.navbar-main-container {
    background-color: white;
    width: 100%;
    height: 2cm;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}
.navbar-browser-container {
    background-color: white;
    width: 98%;
    height: 100%;
    padding-right: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: black;
}
.logo-link {
    // background-color: yellowgreen;
    margin-left: 2%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.only-use-in-navbar {
}
.logo-link-img {
    cursor: pointer;
    margin-left: 2%;
    height: 50%;
}
.navbar-links-container {
    // background-color: yellowgreen;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1%;
}
.link-container {
    // background-color: yellow;
    margin-right: 2%;
}
.login-link-container {
    // background-color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 2%;
    font-size: 100%;
}
.admin-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 10%;
    font-size: 100%;
    background-color: crimson;
    color: white;
    border: 1px solid gainsboro;
    padding: 0.5%;
    padding-right: 1%;
    padding-left: 1%;
    border-radius: 5%;
}
.link {
    color: black;
    text-decoration: none;
    font-size: 100%;
}
.download-app-btn{
    background: none;
    border: none;
    font-size: 100%;
    cursor: pointer;
    margin-right: 1%;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}
.download-app-link {
    background: none;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 100%;
    cursor: pointer;
    margin-left: 1%;
    margin-right: 1%;
    color: black;
}
.signin-user-img {
    width: 17%;
}
.name-user-img {
    width: 10%;
}
.add-card-btn2 {
    background-color: white;
    width: max-content;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border: solid black 1px;
    border-radius: 5px;
    padding: 0.5%;
    cursor: pointer;
    max-lines: 1;
}
.add_card_plus_icon {
    max-lines: 1;
}
.add-label1 {
    cursor: pointer;
    max-lines: 1;
    font-size: 100%;
}
.link-hi {
    // background-color: red;
    font-size: 80%;
    text-align: center;
    color: black;
    text-decoration: none;
}

.profile-menu {
    // background-color: hotpink;
    position: absolute;
    top: 60%;
    right: 13.3%;
    width: 10%;
    height: 180%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    box-shadow: 1px 1px 8px #888888;
}
.profile-menu-btn {
    width: 100%;
    height: 100%;
    border: none;
    background-color: white;
    color: black;
    text-align: start;
    padding-left: 10%;

    &:hover {
        border-left: 2px solid black;
        font-size: 120%;
    }
}

/*--------------bm MENU-----------------*/
.burger-things {
    overflow-y: hidden;
}
.bm-item {
    overflow: hidden;
}
.bm-menu-wrap {
    height: 100%;
    overflow: hidden;
}
.bm-item-list{
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.bm-burger-button {
    position: relative;
    margin-left: 2%;
    float: left;
    margin-top: 10%;
}
.bm-cross-button {
    position: relative;
    float: right;
    margin-right: 2%;
}
.bm-menu {
    background: #2c3e50;
    font-size: 1.15em;
}
.bm-overlay {
    background: rgba(0, 0, 0, 0.5);
}
.mobile-navbar-top-btn {
    height: 6%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 10%;
    color: white;
}
.mobile-navbar-btn {
    width: 90%;
    height: 6%;
    padding-left: 10%;
    font-size: 100%;
    margin-top: 1%;
    margin-bottom: 10%;
    color: white;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    text-decoration: none;
}
/*--------------MOBILE-----------------*/

.logo-link-img-menu {
    // background-color: red;
    width: 70%;
    margin: 15%;
    margin-bottom: 10%;
}
.menu-top-btn {
    background-color: white;
    width: 80%;
    text-align: center;
    border: solid black 1px;
    border-radius: 5px;
    padding: 3%;
    font-size: 90%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 8%;
    color: black;
    text-decoration: none;
}
.menu-top-btn2 {
    background-color: white;
    width: 87%;
    text-align: center;
    border: solid black 1px;
    border-radius: 5px;
    padding: 3%;
    font-size: 90%;
    margin-bottom: 2%;
    margin-left: 5%;
    margin-right: 8%;
    color: black;
    text-decoration: none;
}
.menu-inside-link {
    background-color: white;
    color: black;
    width: 100%;
    padding: 5%;
    padding-left: 15%;
    border-bottom: 1px solid gainsboro;
    text-decoration: none;
    margin-left: -5%;
}
.download-app-btns-div{  
    background-color: white;
    width: 100%;  
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.menu-download-app-btn{
    width: 45%;
    padding: 2%;
    align-self: center;
}
.menu-download-app-lbl{
    // background-color: yellow;
    text-align: center;
    margin-top: 5%;
    width: 70%;
    padding: 5%;
    align-self: center;
    color: white;
    font-size: 150%;
}
.navbar-mobile-container {
    // background-color: yellow;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2cm;
}
.login-link-container-mobile {
    // background-color: green;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5%;
}
.name-user-img-mobile {
    width: 12%;
}
.logo-link-mobile {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 3%;
}
.logo-link-img-mobile {
    width: 100%;
    align-items: flex-end;
    align-self: flex-end;
    align-content: flex-end;    
}

.login-link-mobile-container {
    // background-color: gold;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.login-userName-div-mobile {
    // background-color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.name-link-mobile{
    font-size: 100%;
    margin-left: 5%;
}
@media screen and (max-width: 1280px) {
    .navbar-main-container {
        // background-color: green;
        height: 1.5cm;
    }
    .profile-menu {
        position: absolute;
        top: 70%;
        right: 10%;
        width: 15%;
        height: 180%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-evenly;
        box-shadow: 1px 1px 8px #888888;
    }
    .link-container {
        margin-right: 1.5%;
    }
    .link{
        font-size:70%;
    }
    .add-label1 {
        font-size: 130%;
    }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
    .navbar-main-container {
        // background-color: green;
        height: 2cm;
    }
    .name-link-mobile{
        font-size: 200%;
    }
}
@media screen and (max-width: 700px) {
    .navbar-main-container {
        // background-color: green;
        height: 2cm;
    }
    .name-link-mobile{
        font-size: 100%;
    }
}
@media screen and (max-width: 450px) {
    .navbar-main-container {
        // background-color: green;
        height: 1cm;
    }
    .navbar-mobile-container {
        // background-color: red;
        height: 1cm;
    }
    .link{
        font-size: 100%;
    }
}
