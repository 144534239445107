.choose-plan-main-container {
    background-color: #eff3f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
}
.choose-plan-title-bg {
    background-image: url("../images/examples-bg3.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    width: 100%;
    height: 10%;
    margin-top: 3%;
    font-size: 200%;
    font-weight: bold;
    text-align: center;
    padding: 2%;
    letter-spacing: 2px;
    border-bottom-right-radius: 70%;
    border-bottom-left-radius: 70%;
    position: absolute;
    top: 0;
}
.title-overly {
    background-color: rgba(196, 2, 2, 0.8);
    color: white;
    width: 100%;
    height: 10%;
    margin-top: 3%;
    font-size: 200%;
    font-weight: bold;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
    letter-spacing: 2px;
    border-bottom-right-radius: 70%;
    border-bottom-left-radius: 70%;
    position: absolute;
    top: 0;
}
.choose-plan-title-img {
    width: 100%;
    margin-top: 3%;
}
.plan-cards-div {
    // background-color: wheat;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-evenly;
    width: 80%;
    margin-top: 15%;
    text-align: center;
}
.plan-card-div {
    background-color: white;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    box-shadow: 1px 1px 3px #888888;
    position: relative;
}
.best-seller-lbl {
    margin-top: 2%;
    margin-bottom: 1%;
    font-size: 110%;
    color: #f34c99;
    font-weight: 800;
    letter-spacing: -0.2px;
}
.plan-card-top-lbl {
    width: 100%;
    margin-top: 0%;
    font-size: 250%;
    background-color: gray;
    color: white;
    text-align: center;
    font-weight: 500;
}
.plan-card-top-lbl2 {
    width: 100%;
    margin-top: 0%;
    font-size: 250%;
    background-color: goldenrod;
    color: white;
    text-align: center;
    font-weight: 500;
}
.plan-card-duration-lbl {
    margin-top: 3%;
    font-size: 150%;
    font-weight: bold;
}
.price-lbl {
    margin-top: 3%;
    margin-bottom: 3%;
    font-weight: bold;
    font-size: 250%;
}
.plan-card-per-lbl {
    font-weight: bold;
    color: silver;
    margin-bottom: 3%;
}
.plan-card-includes-lbl {
    width: 90%;
    color: black;
    font-size: 150%;
    max-width: 90%;
    margin-bottom: 20%;
}
.plan-card-includes-lbl-space {
    color: transparent;
    font-size: 100%;
}
.choose-plan-btn {
    margin-top: 8%;
    margin-bottom: 5%;
    background-color: white;
    color: black;
    border: 1.9px solid black;
    padding: 2%;
    padding-right: 10%;
    padding-left: 10%;
    border-radius: 5px;
    font-size: 80%;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    bottom: 3%;
    &:hover {
        background-color: crimson;
        color: white;
    }
}
.plan-card-div2 {
    background-color: white;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    box-shadow: 1px 1px 8px #888888;
    position: relative;
}
.flag-img {
    position: absolute;
    top: -5%;
    right: 0%;
}

.contact-diamond-div {
    // background-color: khaki;
    width: 60%;
    height: 80%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-diamond-back-btn {
    width: 15%;
    padding: 1%;
    margin-bottom: 7%;
    background-color: crimson;
    color: white;
    border: none;
    font-size: 120%;
}
.need-contact-div {
    // background-color: yellow;
    width: 100%;
    padding: 5%;
    height: 80vh;
    font-size: 90%;
    margin-top: 15%;
}
.bottom-bar-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
}
@media screen and (max-width: 1280px) {
    .title-overly-lbl {
        font-size: 160%;
    }
    .bottom-bar-wrapper {
        top: 120%;
    }
    .plan-card-div {
        height: auto;
    }
    .price-lbl {
        font-size: 200%;
    }
    .choose-plan-title-bg {
        margin-top: 3%;
    }
    .title-overly {
        margin-top: 3%;
    }
    .plan-card-includes-lbl {
        font-size: 250%;
        margin-bottom: 25%;
    }
}
@media screen and (max-width: 1030px) {
    .plan-cards-div {
        width: 90%;
        margin-top: 30%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .plan-card-div {
        width: 90%;
        margin-top: 5%;
    }
}
@media screen and (max-width: 1024px) {
    .choose-plan-main-container {
        height: auto;
    }
    .plan-cards-div {
    }
    .plan-card-div {
        height: auto;
    }
    .price-lbl {
        font-size: 260%;
    }
    .plan-card-includes-lbl {
        font-size: 250%;
        margin-bottom: 25%;
    }
    .choose-plan-btn {
        font-size: 180%;
    }
}
@media screen and (max-width: 800px) {
    .choose-plan-main-container {
        height: auto;
    }
    .choose-plan-title-bg {
        height: 12%;
        margin-top: 9%;
        padding: 2%;
        letter-spacing: 2px;
        border-bottom-right-radius: 60%;
        border-bottom-left-radius: 60%;
    }
    .title-overly {
        height: 12%;
        margin-top: 9%;
        padding: 2%;
        border-bottom-right-radius: 60%;
        border-bottom-left-radius: 60%;
    }
    .choose-plan-title-img {
        margin-top: 10%;
    }
    .plan-cards-div {
        // background-color: brown;
        font-size: 80%;
        margin-top: 50%;
        padding-bottom: 5%;
    }
    .plan-card-div {
        width: 90%;
        height: auto;
        font-size: 80%;
        margin-top: 5%;
    }
    .price-lbl {
        width: 90%;
        margin-top: 5%;
        margin-bottom: 5%;
        font-size: 210%;
    }
    .plan-card-includes-lbl {
        font-size: 150%;
        margin-bottom: 25%;
    }
    .choose-plan-btn {
        font-size: 120%;
    }
    .contact-diamond-div {
        width: 95%;
        margin-top: 30%;
    }
    .contact-diamond-back-btn {
        width: auto;
        padding: 3%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .bottom-bar-wrapper {
        width: 100%;
        position: relative;
        bottom: 0;
    }
}
@media screen and (max-width: 768px) and (max-height: 1024px) {
    
    .choose-plan-title-bg {
        margin-top: 7%;
    }
    .title-overly {
        margin-top: 7%;
    }
    .title-overly-lbl {
        font-size:180%;
    }
    .plan-card-duration-lbl {
        font-size:280%;
    }
    .plan-card-includes-lbl {
        font-size:280%;
    }
    .choose-plan-btn {
        font-size:280%;
    }
}

@media screen and (max-width: 600px) {
    .choose-plan-main-container {
        height: auto;
    }
    .choose-plan-title-bg {
        height: 12%;
        margin-top: 9%;
        padding: 2%;
        letter-spacing: 2px;
        border-bottom-right-radius: 60%;
        border-bottom-left-radius: 60%;
    }
    .title-overly-lbl {
        font-size:100%;
    }
    .title-overly {
        height: 12%;
        margin-top: 9%;
        padding: 2%;
        border-bottom-right-radius: 60%;
        border-bottom-left-radius: 60%;
    }
    .choose-plan-title-img {
        margin-top: 10%;
    }
    .plan-cards-div {
        // background-color: brown;
        font-size: 80%;
        margin-top: 50%;
        padding-bottom: 5%;
    }
    .plan-card-div {
        width: 90%;
        height: auto;
        font-size: 80%;
        margin-top: 5%;
    }
    .price-lbl {
        width: 90%;
        margin-top: 5%;
        margin-bottom: 5%;
        font-size: 210%;
    }
    .plan-card-includes-lbl {
        font-size: 150%;
        margin-bottom: 25%;
    }
    .choose-plan-btn {
        font-size: 120%;
    }
    .contact-diamond-div {
        width: 95%;
        margin-top: 30%;
    }
    .contact-diamond-back-btn {
        width: auto;
        padding: 3%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .bottom-bar-wrapper {
        width: 100%;
        position: relative;
        bottom: 0;
    }
}
@media screen and (max-width: 375px) {
    .title-overly-lbl {
        font-size: 95%;
    }
}
@media screen and (max-width: 360px) {
    .price-lbl {
        font-size: 180%;
    }
    .title-overly-lbl {
        font-size: 80%;
    }
}
@media screen and (max-width: 320px) {
    .price-lbl {
        font-size: 180%;
    }
    .title-overly-lbl {
        font-size: 80%;
    }
    .plan-card-duration-lbl {
        font-size: 150%;
    }
}

@media screen and (max-width: 280px) {    
    .title-overly-lbl {
        font-size: 60%;
    }
    .price-lbl {
        font-size: 150%;
    }
    .plan-card-duration-lbl {
        font-size: 150%;
    }
}