.upload-images-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 2%;
}
.upload-image-inside-div {
    width: 100%;
    margin-bottom: 2%;
    height: 100%;
    margin-right: 0.5%;
    background-color: #f7fbfe;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.the-dropzone {
    background-color: white;
    width: 99.35%;
    height: 150px;
    border-color: black;
    border-width: 4px;
    border-style: dashed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.the-dropzone-lbl1 {
    font-size: 100%;
}

.add-img-lbl {
    margin-top: 2%;
    font-weight: bold;
}
.add-img-container {
    width: 100%;
    margin-top: 1%;
    background-color: white;
    border: 1px solid gainsboro;
}

.add-img-btns-container {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 1%;
    margin-bottom: 1%;
}
.add-img-btn {
    width: 40%;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding-top: 1%;
    padding-bottom: 1%;
}

@media screen and (max-width: 800px) {
    .upload-images-div {
        // background-color: gainsboro;
        width: 97%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .upload-image-inside-div {
        width: 100%;
        margin-bottom: 2%;
        height: 100%;
        margin-right: 0%;
        background-color: #f7fbfe;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .add-img-lbl {
        font-size: 90%;
        margin-bottom: 2%;
    }

    .the-dropzone-lbl1 {
        font-size: 80%;
    }
    .the-dropzone {
        // background-color: red;
        width: 98%;
        height: 150px;
        border-color: black;
        border-width: 3px;
        border-style: dashed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .add-img-btns-container {
        width: 80%;
    }
}
