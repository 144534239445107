.buisness-cards-page-container {
    // background-color: yellow;
    width: 100%;
    height: 100%;
    position: relative;
}
.buisness-cards-page-container-browser {
    // background-color: yellow;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
}
.buisness-cards-page-container-mobile {
    // background-color: yellow;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
}
.annoying-change-psw {
    background-color: crimson;
    width: 300px;
    height: 2cm;
    top: 0%;
    left: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow-x: hidden;
    z-index: 10000;
    position: absolute;
}
.annoying-change-psw-btn {
    border: 1px solid gainsboro;
    padding-left: 5%;
    padding-right: 5%;
}
.annoying-change-psw-lbl {
    font-size: 100%;
    color: black;
    font-weight: bold;
}
.buisness-cards-inside {
    background-color: #fafbfb;
    width: 95%;
    height: 100%;
    margin: 3%;
    position: relative;
}
.change-plan-popup {
    background-color: white;
    width: 60%;
    height: 60%;
    position: absolute;
    top: 15%;
    left: 25%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 5px solid royalblue;
    border-radius: 10px;
}
.change-plan-popup-x-btn {
    position: absolute;
    top: 5%;
    right: 2%;
    padding: 0.5%;
    padding-left: 0.7%;
    padding-right: 0.7%;
    border-radius: 50%;
    border: 1px solid black;
    cursor: pointer;
}
.change-plan-title-lbl {
    font-size: 200%;
    margin-bottom: 5%;
}
.current-div {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 150%;
    color: green;
    margin-bottom: 1%;
}

.plan-cards-div2 {
    // background-color: wheat;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-evenly;
    width: 60%;
    height: 45%;
    margin-top: 0%;
}
.are-you-sure-popup {
    background-color: white;
    width: 40%;
    height: 50%;
    position: absolute;
    top: 15%;
    left: 35%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 5px solid crimson;
    border-radius: 10px;
    font-size: 200%;
    text-align: center;
}
.are-you-sure-img {
    height: 50%;
}
.are-you-sure-title-lbl {
    font-weight: bold;
}
.are-you-sure-title-lbl2 {
    font-weight: bold;
    color: red;
    font-size: 50%;
}
.are-you-sure-name-lbl {
}
.are-you-sure-btns-div {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.are-you-sure-btn {
    width: 25%;
    background-color: whitesmoke;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 2%;
    font-weight: bold;
    cursor: pointer;
}
.buisness-cards-inside-mobile {
    // background-color: red;
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 15%;
}
.tabs-and-search {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.search-div {
    width: 50%;
    background-color: white;
    border: 1px solid gainsboro;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.my-search {
    width: 90%;
    padding: 1.5%;
    border: none;
}
.search-img {
    width: 20px;
}
.buisness-cards-title {
    font-size: 120%;
    font-weight: bold;
}
.buisness-cards-title-mobile {
    font-size: 120%;
    font-weight: bold;
    margin: 3%;
}
.add-card-btn {
    position: absolute;
    top: 5%;
    right: 0%;
    padding: 0.5%;
    padding-left: 1%;
    padding-right: 1%;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 90%;
    cursor: pointer;
}
.add-card-btn-mobile {
    top: 1%;
    right: 0%;
    padding: 2%;
    margin: 5%;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 90%;
}
.the-table {
    position: relative;
}
.pending-pay-div {
    // background-color: yellow;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 150%;
}
.pending-lbl {
    font-size: 100%;
}
.pay-btn2 {
    background-color: yellowgreen;
    padding: -5%;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.edit-menu2 {
    position: relative;
}
.edit-menu-btn-div {
    // background-color: red;
    width: 90%;
    height: 25.5%;
    margin: 1%;
    margin-top: 9%;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        border-left: 2px solid black;
    }
}
.menu-btn-label {
    // background-color: yellow;
    font-size: 130%;
    width: 70%;
    margin-left: 5%;
    text-align: start;
    cursor: pointer;
}
.menu-btn-icon {
    // background-color: pink;
    width: 60%;
    height: 100%;
    margin-left: 3%;
}
.menu-btn-icon2 {
    // background-color: pink;
    cursor: pointer;
}

.buisness-cards-table-tabs {
    // background-color: yellow;
    width: 40%;
    height: 40px;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.buisness-cards-table-tabs-mobile {
    // background-color: yellow;
    width: 100%;
    height: 40px;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.table-tab {
    // background-color: red;
    cursor: pointer;
    width: 24%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
}
.table-line {
    background-color: gainsboro;
    height: 1px;
    width: 100%;
    margin-bottom: 1%;
    margin-bottom: 2%;
}

.dots-icon {
    font-size: 120%;
    width: 100%;
    cursor: pointer;
}
.the-list-div {
    // background-color: red;
    width: 90%;
    margin: 5%;
    display: flex;
    flex-direction: column;
}
.list-row-top-div {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.row-name-lbl-list {
    width: 70%;
    margin-right: 20%;
}
.mobile-remove-card-btn {
    width: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #e31500;
    padding: 1%;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin: 5%;
    margin-left: 0%;
}
.row-img-mobile-list {
    width: 60px;
    height: 60px;
    padding: 1%;
    margin: 5%;
}
.list-row-views-div {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 1%;
}
.list-row-views-lbl {
    background-color: #b3c0ce;
    color: white;
    width: 95%;
    height: 100%;
    padding: 1%;
    padding-left: 5%;
}
.list-row-views-val {
    // background-color: yellow;
    width: 100%;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 5%;
}
.list-row-status-div {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.list-row-status-row {
    // background-color: red;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
}
.list-row-status-lbl {
    // background-color: yellowgreen;
    width: 100%;
    text-align: center;
}
.pay-btn {
    background-color: yellowgreen;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 100%;
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.dots-icon2 {
    margin-left: 10%;
    margin-right: 10%;
}
.list-row-line {
    background-color: gainsboro;
    height: 2px;
    width: 100%;
    margin-top: 15%;
    margin-bottom: 15%;
}
.associatedPlan-lbl {
    font-size: 90%;
}
@media screen and (max-width: 1350px) {
    .buisness-cards-table-tabs {
        width: 50%;
    }
    .my-search {
        width: 50%;
    }
}
@media screen and (max-width: 500px) {
    .annoying-change-psw {
        top: 0%;
        left: 10%;
    }

    .search-div {
        width: 85%;
    }
    .my-search {
        width: 85%;
    }

    .are-you-sure-popup {
        background-color: white;
        width: 85%;
        left: 5%;
        font-size: 140%;
        text-align: center;
    }
    .are-you-sure-img {
        height: 30%;
    }
    .are-you-sure-btns-div {
        width: 100%;
    }
    .are-you-sure-btn {
        width: 35%;
    }
}
