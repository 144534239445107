.admin-cards-page-container {
    background-color: #fafbfb;
    width: 100%;
    height: 100%;
}
.admin-cards-page-container-browser {
    // background-color: yellow;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: hidden;
    margin-top: 3%;
}
.are-you-sure-popup-admin {
    background-color: white;
    width: 40%;
    height: 50%;
    position: fixed;
    top: 15%;
    left: 35%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 5px solid crimson;
    border-radius: 10px;
    font-size: 200%;
    text-align: center;
}
.admin-cards-inside {
    // background-color: red;
    width: 95%;
    height: 100%;
    margin: 3%;
    position: relative;
}
.status-select {
    // background-color: red;
    width: 150px;
}
.status-select-and-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.status-select-loading{
}
.title-and-exel-btn-div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.exel-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 3%;
    font-size: 100%;
    background-color: crimson;
    color: white;
    border: 1px solid gainsboro;
    padding: 0.5%;
    padding-right: 1%;
    padding-left: 1%;
    border-radius: 5%;
}
.admin-cards-title {
    font-size: 120%;
    font-weight: bold;
}
.all-searches {
    // background-color: skyblue;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 1%;
}
.a-search-div {
    width: 25%;
    background-color: white;
    border: 1px solid gainsboro;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.all-selects {
    // background-color: wheat;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 0.5%;
}
.a-select-div {
    // background-color: red;
    width: 25%;
}
.edit-cards-btn {
    padding: 1%;
}
.all-tabs {
    // background-color: gainsboro;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}
.all-select-dates {
    // background-color: gainsboro;
    // width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 0.5%;
}
.all-select-dates-title {
    margin-left: 2%;
    font-weight: bold;
}
.a-select-date-title {
    margin-left: 5%;
}
.a-select-dates {
    padding: 2%;
    margin-left: 3%;
}
.remove-dates-btn {
    padding: 0.5%;
    margin-left: 3%;
}

.edit-selected-group-div{
    background-color: whitesmoke;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}
.edit-group-btns-div{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.edit-selected-group-btn{
    height: 100%;
    margin-right: 5%;

}
.group-edit-lbls-div{
    // background-color: yellow;
    width: 80%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 200%;
}
.group-edit-lbl1{
}
.group-edit-lbl2{
    font-weight: bold;

}
.all-edit-divs{
    // background-color: blue;
    width: 80%;
    overflow: hidden;
}
.all-edit-left-divs{
    // background-color: yellowgreen;
    margin-top: -5%;
    width: 100%;
    overflow: "overlay";
}
.save-group-design-btn{
    background-color: crimson;
    color: white;
    border: 1px solid gainsboro;
    border-radius: 5px;
    width: 90%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-size: 120%;
    margin-left: 5%;
    margin-bottom: 5%;
}
.loading-group-edit-popup{
    background-color: white;
    width: 40%;
    height: 50%;
    position: absolute;
    top: 20%;
    left: 20%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 5px solid crimson;
    border-radius: 10px;
    font-size: 200%;
    text-align: center;
}