.checkout-main-container {
    // background-color: rebeccapurple;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    width: 100%;
    height: 100vh;
}
.checkout-title-div {
    background-color: rgba(196, 2, 2, 0.8);
    width: 100%;
    height: 10vh;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.checkout-title-lbl {
    font-size: 250%;
    color: white;
}
.checkout-bottom-div {
    // background-color: red;
    width: 60%;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    margin-bottom: 2%;
}
.cards-div {
    // background-color: gold;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border: 1px solid gainsboro;
    width: 60%;
    height: 100%;
    margin-right: 5%;
    border-radius: 5px;
}
.radio-btn {
    margin-left: 3%;
    margin-right: 3%;
}
.pay-method-div {
    // background-color: greenyellow;
    width: 40%;
    // height: 100%;
    border: 1px solid gainsboro;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.proceed-btn-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.proceed-btn {
    width: 70%;
    background-color: #bbc2d2;
    padding: 3%;
    padding-left: 10%;
    padding-right: 10%;
    margin: 2%;
    text-align: center;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.pay-card-name-lbl2 {
    width: 100%;
    font-size: 120%;
    font-weight: bold;
    margin-left: 10%;
}
.pay-card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid gainsboro;
    width: 100%;
}
.pay-method-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid gainsboro;
}
.pay-card-row-img {
    width: 20%;
    padding: 5%;
}
.pay-card-name-lbl {
    // background-color: gold;
    width: 100%;
    font-size: 120%;
    font-weight: bold;
}
.pay-card-money-lbl {
    margin-right: 5%;
}

.top-div {
    // background-color: pink;
    width: 30%;
    height: 50px;
    margin-top: 3%;
    position: relative;
}
.space-line {
    background-color: gainsboro;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 30%;
    z-index: -1;
}
.todo-btns {
    // background-color: yellow;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    z-index: 100;
}
.btn-and-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.todo-btn {
    border-radius: 50%;
    background-color: gainsboro;
    border: 1px solid gainsboro;
    color: white;
    padding: 4%;
    padding-left: 6%;
    padding-right: 6%;
}
.todo-lbl {
    color: gainsboro;
}
.recurring-payment-div {
    // background-color: yellow;
    width: 50%;
    margin: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.recurring-payment-checkbox {
}
.recurring-payment-lbl {
}
.step2-cards-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.selected-items-title {
    // background-color: yellow;
    width: 100%;
    text-align: center;
    font-size: 150%;
}
.step2-items-div {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
    margin-top: 2%;
}
.pay-row {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;
    margin-bottom: 2%;
    border-bottom: 1px solid gainsboro;
}
.selected-item-title {
    font-weight: bold;
}
.selected-price-title {
    font-weight: bold;
}
.selected-total-title {
    font-weight: bold;
}
.selected-totalPrice-title {
    font-weight: bolder;
}

@media screen and (max-width: 1280px) {
    .top-div {
        width: 80%;
        font-size: 90%;
    }
    .todo-btn {
        padding: 3.5%;
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (max-width: 800px) {
    .checkout-title-div {
        margin-top: 10%;
    }
    .todo-btn {
        width: 30px;
        height: 30px;
    }
    .top-div {
        width: 80%;
        font-size: 50%;
    }
    .checkout-bottom-div {
        display: flex;
        flex-direction: column;
        width: 90%;
        font-size: 80%;
        margin-bottom: 10%;
        min-height: 51vh;
    }
    .cards-div {
        width: 100%;
        margin-bottom: 10%;
    }
    .pay-method-div {
        width: 100%;
    }
}
