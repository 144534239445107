.advantages-component-main-container {
    // background-color: slateblue;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
}
.advantages-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
}
.adv-title {
    width: 100%;
    font-size: 250%;
    margin-top: 2%;
    text-align: center;
}
.adv-img {
    width: 100%;
    margin-left: -2%;
}
.div2-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: top;
}
.adv-title-mobile {
    width: 100%;
    font-size: 170%;
    margin-top: 15%;
    text-align: center;
    margin-bottom: 5%;
}
.adv-img-div{
    width: 60%;
}
.all-adv-boxes-div{
    // background-color: wheat;
    width: 90%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.all-adv-boxes {
    // background-color: skyblue;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.adv-box1 {
    width: 100%;
    // background-color: blanchedalmond;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10%;
}
.adv-box1-titles {
    // background-color: blanchedalmond;
    width: 80%;
    margin-left: 3%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.round-blue-circle {
    background-color: #50d0e3;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.adv-mobile-icon {
    color: white;
}
.adv-mobile-icon2 {
    width: 25px;
    height: 25px;
}
.adv-mobile-img {
}
.adv-inside-title {
    font-size: 110%;
}
.adv-inside-mini-title {
    margin-top: 1%;
    font-size: 80%;
    color: #70879e;
}

.adv-img-ipad {
    width: 130%;
    margin-left: -14%;
}

.adv-title-mobile {
    width: 100%;
    font-size: 220%;
    margin-top: 8%;
    text-align: center;
    margin-bottom: 5%;
}
