.saved-cards-main-container {
    // background-color: blanchedalmond;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}
.fav-tabs-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 10%;
}
.fav-tabs {
    // background-color: red;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 2%;
}
.table-tab2 {
    // background-color: red;
    cursor: pointer;
    width: 50%;
    height: 100%;
    margin-right: 5%;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 5%;
}
.title-and-search {
    // background-color: red;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8%;
    margin-bottom: 2%;
}
.saved-card-title {
    // background-color: skyblue;
    font-size: 130%;
    font-weight: bold;
}
.saved-card-top-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}
.saved-card-top-row {
    padding-top: 0.1%;
    padding-bottom: 0.1%;
}
.saved-card-list-div {
    width: 90%;
    margin-bottom: 5%;
}
.saved-card-row {
    background-color: white;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.1%;
}
.saved-card-top-row-lbl {
    margin-left: 12%;
    text-align: left;
}
.saved-card-im-lbl-div {
    // background-color: skyblue;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    align-items: center;
    padding-top: 0%;
    padding-bottom: 0%;
    cursor: pointer;
    cursor: pointer;
}
.saved-card-row-img {
    height: 70%;
    border-radius: 5px;
    border: 1px solid gainsboro;
}
.saved-card-row-lbl {
    // background-color: yellow;
    margin-left: 3%;
}
.saved-card-row-x-btn {
    // background-color: green;
    margin-right: 6%;
    padding: 1%;
    cursor: pointer;
}
@media screen and (max-width: 1280px) {
    .fav-tabs {
        width: 30%;
    }
}
@media screen and (max-width: 500px) {
    .saved-cards-main-container {
        // background-color: blanchedalmond;
        margin-top: 5%;
    }
    .title-and-search {
        // background-color: red;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .saved-card-title {
        // background-color: skyblue;
        width: 100%;
        text-align: center;
        font-size: 110%;
        margin-bottom: 5%;
    }
    .fav-tabs {
        width: 90%;
    }
    .table-tab2 {
        margin-right: 5%;
    }
    .saved-card-row {
        background-color: white;
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1%;
    }
}
