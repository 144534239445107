.add-design-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 10px;
    margin-top: 2%;
    margin-bottom: 5%;
    position: relative;
}
.add-design-lbl {
    background-color: #f7fbfe;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-size: 120%;
    font-weight: bold;
    text-align: center;
}
.selects-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.select-with-title-div {
    // background-color: blue;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 1%;
    margin-bottom: 3%;
}
.select-title-lbl {
    font-weight: bold;
    margin-bottom: 2%;
}
.my-design-select {
    width: 90%;
    z-index: 500;
}
.my-color-picker {
    position: absolute;
    top: 50%;
    left: 58%;
    z-index: 600;
}
.my-color-picker-close-btn {
    position: absolute;
    top: -5%;
    left: -7%;
}

@media screen and (max-width: 800px) {
    .add-design-container {
        width: 95%;
    }
    .selects-div {
        flex-direction: column;
    }

    .select-with-title-div {
        width: 90%;
    }
    .my-color-picker {
        position: absolute;
        top: 45%;
        left: 15%;
        z-index: 600;
    }
    .my-color-picker-close-btn {
        position: absolute;
        top: -5%;
        left: -7%;
        font-size: 150%;
    }
}
