.home-top-component-main-container-desktop {
    background-image: url("../images/examples-bg3.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 75vh;
    overflow: hidden;
    margin-top: 2cm;
}
.home-top-component-main-container-mobile {
    width: 100%;
    overflow: hidden;
    margin-top: 1cm;
}

.annoying-change-psw-main {
    background-color: yellow;
    width: 50%;
    top: 15%;
    left: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow-x: hidden;
    z-index: 10000;
    position: absolute;
    padding: 3%;
}
.annoying-change-psw-btn-main {
    font-size: 150%;
    font-weight: bold;
    border: 1px solid gainsboro;
    padding: 2%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 5%;
}
.annoying-change-psw-lbl-main {
    font-size: 200%;
    width: 80%;
    height: 50%;
    color: black;
    font-weight: bold;
    text-align: center;
}
.annoying-change-psw-btn-x {
    background-color: transparent;
    position: absolute;
    top: 5%;
    right: 2%;
    padding: 0.5%;
    padding-left: 0.7%;
    padding-right: 0.7%;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 200%;
}
.home-top-lbl-wh {
    width: 70%;
    color: black;
    margin: 1%;
    font-weight: bold;
    font-size: 50%;
    text-align: center;
}
.home-top-lbl-version {
    font-size: 50%;
    margin-left: 1.2%;
    width: 70%;
    text-align: center;
}
.bg-overly-desktop {
    background-color: rgba(196, 2, 2, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}
.bg-overly-mobile {
    background-color: crimson;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 10%;
    padding-top: 5%;
}
.home-top-left-div {
    // background-color: chartreuse;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.home-top-right-div-desktop {
    // background-color: red;
    margin-top: 10%;
    color: white;
    width: 100%;
    margin-right: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home-top-right-div-mobile {
    // background-color: red;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.ng-phone-img {
    height: 75%;
    margin-right: 10%;
}
.create-btn {
    width: 50%;
    margin-top: 5%;
    color: white;
    border: none;
    padding: 2%;
    padding-right: 5%;
    padding-left: 5%;
    border-radius: 30px;
    font-size: 100%;
    cursor: pointer;
    background-color: black;
    overflow: hidden;

    &:hover {
        background-color: transparent;
        border: 2px white solid;
        transition: 0.1s;
    }
}
.home-top-lbl1 {
    width: 70%;
    font-size: 350%;
    font-weight: bold;
    letter-spacing: -1.5px;
    text-align: center;
}
.home-top-lbl11 {
    // background-color: bisque;
    width: 70%;
    font-size: 350%;
    font-weight: bold;
    letter-spacing: -1.5px;
    text-align: center;
}
.home-top-lbl111 {
    font-size: 210%;
    font-weight: bold;
}
.home-top-lbl1111 {
    font-size: 210%;
    font-weight: bold;
}
.home-top-lbl2 {
    // background-color: bisque;
    width: 70%;
    font-size: 210%;
    letter-spacing: -1px;
    text-align: center;
}
.home-top-lbl22 {
    font-size: 160%;
    letter-spacing: -1px;
}
.holding_Ngcar_img {
    width: 100%;
}
.bootom-lbl-and-img-div {
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.home-bottom-lbl {
    color: black;
    font-weight: bold;
    text-align: center;
}
.home-bottom-lbl1 {
    color: black;
    font-weight: bold;
    text-align: center;
    margin-top: 5%;
    font-style: italic;
}

.home-bottom-lbl2 {
    color: black;
    font-weight: bold;
    text-align: center;
    font-style: italic;
}

@media screen and (max-width: 1536px)  and (max-height: 722px) {
    .home-top-component-main-container-desktop {
        height: 95vh;
    }
}
@media screen and (max-width: 1350px) {
    .create-btn {
        width: 60%;
    }
}
@media screen and (max-width: 1280px) {
    .home-top-component-main-container-desktop {
        margin-top: 1.5cm;
        height: 90vh;
    }
    .home-top-component-main-container-mobile {
        margin-top: 0cm;
    }
    .create-btn {
        width: 60%;
    }
    .home-top-lbl1 {
        font-size: 250%;
    }
    .home-top-lbl11 {
        font-size: 250%;
    }
    .home-top-lbl2 {
        font-size: 150%;
    }
}
@media screen and (max-width: 1130px) {
    .ng-phone-img {
        margin-top: 40%;
        margin-right: 5%;
    }
}
@media screen and (max-width: 930px) {
    .create-btn {
        width: 80%;
    }
}
@media screen and (max-width: 780px) {
    .create-btn {
        width: 80%;
    }
    .ng-phone-img {
        margin-top: 40%;
        margin-right: 5%;
    }

    .annoying-change-psw-main {
        width: 85%;
        top: 9%;
        left: 5%;
        padding: 3%;
    }
    .annoying-change-psw-btn-main {
        font-size: 100%;
    }
    .annoying-change-psw-lbl-main {
        font-size: 100%;
        width: 80%;
    }
    .annoying-change-psw-btn-x {
        background-color: transparent;
        position: absolute;
        top: 2%;
        font-size: 150%;
    }
}

@media screen and (max-width: 550px) {
    .home-top-left-div {
        width: 0%;
        // height: 0%;
    }
    .home-top-right-div {
        align-items: center;
    }
    .create-btn {
        margin-top: 5%;
        padding: 5%;
    }
}

@media screen and (max-width: 450px) {
    .home-top-component-main-container-mobile {
    }
    .create-btn {
        padding-bottom: 4%;
    }
}
@media screen and (max-width: 415px) {
    .home-top-lbl111 {
        font-size: 240%;
    }
    .home-top-lbl1111 {
        font-size: 240%;
    }
}
@media screen and (max-width: 360px) and (max-height: 671px) {
    .create-btn {
        padding-bottom: 5%;
    }
    .home-top-lbl22 {
        font-size: 150%;
    }
}
@media screen and (max-width: 360px) and (max-height: 640px) {
    .home-top-lbl22 {
        font-size: 150%;
    }
}
@media screen and (max-width: 325px) {
    .home-top-lbl111 {
        font-size: 150%;
        font-weight: bold;
        margin-top: 5%;
    }
    .home-top-lbl1111 {
        font-size: 150%;
        font-weight: bold;
        margin-bottom: 5%;
    }
    .home-top-lbl22 {
        font-size: 120%;
        letter-spacing: -1px;
    }
    .bootom-lbl-and-img-div {
        font-size: 80%;
    }
    .create-btn {
        padding-bottom: 5%;
    }
}
@media screen and (max-width: 280px) {
    .create-btn {
        font-size: 80%;
    }
}
