.read-excel-page-main-container {
    background-color: whitesmoke;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
}

.enter-file-and-admin-div{
    // background-color: red;
    width: 50%;
    padding: 2%;
    margin-bottom: 10%;
    margin-left: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading-admin{
    // background-color: red;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5%;

}
.admin-input-and-loading-div{   
    width: 60%; 
    // background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.add-admin-input{
    background-color: white;
    width: 90%;
    padding: 2%;
    margin-bottom: 2%;
    margin-top: 2%;
    border: 1px solid gainsboro;
}
.read-excel-input {
    background-color: white;
    width: 80%;
    padding: 2%;
    margin-bottom: 2%;
    margin-top: 2%;
}
.admin-error-label{
    width: 80%;
    color: red;
    text-align: center;
}
.all-errors-div{
    // background-color: white;
    width: 100%;
    height: 100%;
    padding: 2%;
    margin-bottom: 2%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.all-errors-lbl{
    margin-top: 5%;
    font-size: 200%;
    color: red;

}
.all2{
    // background-color: blue;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.all-divs {
}
.all-divs {
    // background-color: pink;
    width: 75%;
    margin-top: 10%;
}
.all-left-divs {
    // background-color: yellowgreen;
    margin-top: 2%;
    width: 100%;
    overflow: "overlay";
}
.upload-cover-img-div {
    margin-top: 7%;
    margin-left: 5%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.card-design-div1 {
    margin-top: 1%;
    margin-left: 5%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.all-people-div {
    background-color: whitesmoke;
    width: 67%;
    margin-top: 0%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid gainsboro;
    border-radius: 10px;
    margin-bottom: 2%;
}
.save-all-btn{
    background-color: crimson;
    color: white;
    border: 1px solid gainsboro;
    border-radius: 5px;
    width: 67%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-size: 120%;
    margin-left: 4%;
    margin-bottom: 10%;
    cursor: pointer;
}
.a-person-div {
    background-color: white;
    width: 95.9%;
    display: flex;
    flex-direction: column;
    margin-top: 0.2%;
    padding: 2%;
}
.people-title {
    background-color: #f7fbfe;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-size: 120%;
    font-weight: bold;
    text-align: center;
}




.main-preview-div {
    background-color: gainsboro;
    margin-top: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-preview-div1 {
    // background-color: yellowgreen;
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    right: 0%;
}
.preview-phone-div2 {
    background-image: url("../images/empty-phone.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    width: 11cm;
    height: 22cm;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;
    right: 2%;
    margin-top: 2%;
}


@media screen and (max-width: 1450px) {
}
@media screen and (max-width: 1350px) {
    .all-left-divs {
    }
}

@media screen and (max-width: 1100px) {
    .all-left-divs {
    }
}
@media screen and (max-width: 700px) {
    .all-divs {
        // background-color: skyblue;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 10%;
    }
    .all-left-divs {
        // background-color: yellowgreen;
        position: fixed;
        overflow-y: scroll;
    }
    .all-people-div {
        background-color: whitesmoke;
        width: 90%;
        margin-top: 0%;
        margin-left: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid gainsboro;
        border-radius: 10px;
        margin-bottom: 120%;
    }
}
