.sign-up-page-container{
    background-image: url("../images/examples-bg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
}
 
.sign-up-form-container{    
    height: 75%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    font-size: 250%;
    margin: 5%;
    margin-top: 0%;
    border-radius: 10px;
}
.sign-up-title{    
    text-shadow: 1px 0px gray;
    font-size: 250%;
    margin-top: 13%;
}

.sign-up-input{
    width: 80%;
    margin-top: 3%;
    border: none;
    border-radius: 5px;
    background: rgb(255,255,255);
    padding: 2.5%;
    direction: ltr;
}
.error_msg_lbl{
    font-size: 100%;
    font-weight: bolder;
    margin-top: 3%;
    color: black;
}
.sign-up-btn{
    font-size: 80%;
    margin-bottom: 5%;
    margin-top: 2%;
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 1%;
    padding-bottom: 1%;
    border: solid 1px gainsboro;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    color: black;
}


@media screen and (max-width:1920px) {
    .sign-up-form-container{    
        font-size: 150%;
    }
}
@media screen and (max-width: 1280px) {
    .sign-up-page-container{   
        height: 75vh;
        margin-top: 8%;  
        width: 70%;
    }
    .sign-up-title{ 
        font-size: 200%; 
        margin-top: 1%;
    }
}
@media screen and (max-width: 800px) {
    .sign-up-page-container{   
        height: 80vh;
        margin-top: 20%;   
        width: 90%; 
    }
     
    .sign-up-form-container{  
        height: 85%;
        width: 90%;
        margin-top: 2%;
    }
    .sign-up-title{ 
        font-size: 200%; 
        margin-top: 1%;
    }
    .sign-up-input-invalid{  
        margin-bottom: 6%;
        padding: 5%;
    }
    
    .sign-up-input{        
        margin-bottom: 6%;
        padding: 5%;
    }
    .sign-up-btn{
        font-size: 80%;
    }
}
 



