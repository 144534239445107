.add-social-media-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 10px;
    margin-top: 2%;
    margin-bottom: 5%;
}

.add-social-media-input {
    background-color: white;
    width: 95%;
    border: 1px solid gainsboro;
    padding: 1.3%;
    margin-left: 2%;
    border-radius: 5px;
}
.media-select-lbl {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 130%;
}
.select-media-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 1%;
    padding-right: 5%;
}
.my-select {
    width: 90%;
    z-index: 10000;
}
.add-social-media-btn {
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 8px;
    padding: 1%;
    margin-left: 1%;
}

.a-social-media-row {
    background-color: #f7fbfe;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    border: 1px solid gainsboro;
    border-radius: 10px;
    margin-bottom: 2%;
    overflow: hidden;
    padding-top: 1%;
    padding-bottom: 1%;
}
.social-name-and-remove-btn{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5%;
}
.social-media-remove-btn {
    margin-right: 5%;
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid red;
    color: red;
    padding: 0.4%;
    padding-left: 0.7%;
    padding-right: 0.7%;
}
.a-social-media-lbl1 {
    // background-color: yellowgreen;
    font-weight: bold;
    font-size: 150%;
    margin-left: 5%;
    width: 10%;
}
.a-social-media-lbl2 {
    // background-color: wheat;
    font-size: 100%;
    width: 90%;
    overflow: hidden;
    margin-left: 5%;
    margin-right: 5%;
}
@media screen and (max-width: 1030px) {
    .select-media-div {
        width: 92%;
    }
    .add-social-media-btn {
        background-color: white;
        border: 1px solid gainsboro;
        border-radius: 5px;
        padding-left: 6%;
        padding-right: 6%;
    }
}
@media screen and (max-width: 800px) {
    .add-social-media-container {
        width: 100%;
        padding-bottom: 5%;
    }
    .select-media-div {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 1%;
        padding-right: 5%;
    }

    .my-select {
        width: 90%;
        z-index: 10000;
        margin-bottom: 5%;
    }

    .add-social-media-input {
        background-color: white;
        width: 93%;
        border: 1px solid gainsboro;
        padding: 3.5%;
        margin-left: 0%;
        border-radius: 5px;
    }

    .media-select-lbl {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 100%;
    }
    .my-select {
        width: 100%;
        z-index: 10000;
        margin-top: 5%;
    }
    .add-social-media-btn {
        background-color: white;
        border: 1px solid gainsboro;
        border-radius: 8px;
        padding: 3%;
        padding-left: 7%;
        padding-right: 7%;
        margin-left: 0%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .a-social-media-row {
        font-size: 80%;
        border-radius: 5px;
        margin-top: 5%;
        margin-bottom: 0%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 0%;
        padding-bottom: 0%;
    }

    .a-social-media-lbl1 {
        // background-color: skyblue;
        font-weight: bold;
        font-size: 150%;
        margin-left: 2%;
        margin-bottom: 0%;
        width: 90%;
    }
    .a-social-media-lbl2 {
        // background-color: rosybrown;
        font-size: 100%;
        margin-bottom: 1%;
        margin-left: 2%;
        overflow: hidden;
        width: 250px;
    }
    .social-media-remove-btn {
        // background-color: black;
        margin-bottom: 0%;
        margin-top: 1%;
        padding: 1%;
        padding-left: 2%;
        padding-right: 2%;
    }
    .social-name-and-remove-btn{
        width: 100%;
        margin-bottom: 0%;
    }
}
