.select-video-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin-top: 2%;
    margin-bottom: 2%;
}
.add-video-input{
    background-color: white;
    width: 70%;
    border: 1px solid gainsboro;
    padding: 1.3%;
    margin-left: 2%;
    border-radius: 5px;
}
.a-video-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 91%;
    background-color: #f7fbfe;
    border: 1px solid gainsboro;
    border-radius: 10px;
    margin-bottom: 2%;
    margin-left: 2%;
    padding: 1%;
}
.a-video-lbl1 {
    // background-color: yellowgreen;
    font-weight: bold;
    font-size: 150%;
    margin-left: 3%;
    width: 50%;
}
.a-video-lbl2 {
    // background-color: wheat;
    font-size: 100%;
    width: 50%;
    overflow: hidden;
    margin-left: 5%;
    margin-right: 5%;
}
.video-remove-btn{
    margin-right: 3%;
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid red;
    color: red;
    padding: 0.4%;
    padding-left: 0.7%;
    padding-right: 0.7%;
}
@media screen and (max-width: 800px) {
    .select-video-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .add-video-input{
        background-color: white;
        width: 75%;
        border: 1px solid gainsboro;
        padding: 3%;
        margin-left: 0%;
        margin-top: 3%;
        border-radius: 5px;
    }
    .a-video-row{
        // background-color: bisque;
        flex-direction: column;
    }
    .a-video-lbl1 {
        width: 98%;
        font-size: 100%;
        margin-top: 2%;
        margin-left: 2%;
    }
    .a-video-lbl2 {
        width: 90%;
        margin: 3%;
    }
    .video-remove-btn{
        margin-right: 0%;
        padding: 2%;
        padding-left: 3%;
        padding-right:3%;
        margin-bottom: 1%;
    }
}