.contact-component-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-title {
  font-size: 200%;
  margin-top: 2%;
  margin-bottom: 2%;
}
.contact-title-mobile {
  font-size: 150%;
  margin-top: 4%;
  margin-bottom: 4%;
}
.contact-inside-div {
  // background-color: grey;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.contact-labels-div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.contact-inside-div-mobile {
  // background-color: grey;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.contact-mobile-div {
  // background-color: grey;
  width: 98%;
  display: flex;
  flex-direction: column;
  padding-left: 2%;
}
.title-div-mobile {
  // background-color: yellow;
  margin-bottom: 3%;
}

.contact-input-label-mobile {
  margin-right: 2%;
  margin-bottom: 8%;
  font-size: 100%;
}

.contact-input-label-must-mobile {
  color: red;
}
.contact-input-mobile {
  background-color: white;
  width: 90%;
  margin-right: 2%;
  margin-bottom: 5%;
  border: none;
  font-size: 110%;
  padding: 3%;
}
.contact-msg-mobile {
  background-color: white;
  width: 86%;
  margin-right: 2%;
  margin-bottom: 5%;
  border: none;
  font-size: 110%;
  padding: 5%;
  height: 100px;
  
  &::placeholder {
    font-family: Arial;
    font-size: 100%;
  }

}
.contact-top-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-div {
  // background-color: yellow;
  width: 100%;
  margin-right: 22%;
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.contact-input-label {
  margin-right: 10%;
  margin-bottom: 8%;
  font-size: 100%;
}

.contact-input-label-must {
  color: red;
}

.contact-input {
  background-color: white;
  width: 33%;
  margin-right: 2%;
  border: 1px solid gainsboro;
  font-size: 110%;
  padding: 1%;
}
.title-and-input-div {
  background-color: yellow;
}
.contact-msg-box {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-msg-box-mobile {
  // background-color: blue;
  width: 92%;
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.msg-input {
  background-color: white;
  width: 96%;
  height: 100px;
  font-size: 150%;
  padding: 2%;
  &::placeholder {
    font-family: Arial;
    font-size: 75%;
  }
}
.contact-msg-label {
  // background-color: bisque;
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.send-btn {
  margin-top: 1%;
  margin-bottom: 4%;
  background-color: crimson;
  background-color: rgba(196, 2, 2, 1); 
  color: white;
  width: 100%;
  padding: 1%;
  font-size: 120%;
  border: none;
  cursor: pointer;
}
.send-btn-mobile {
  width: 96%;
  margin-top: 1%;
  margin-bottom: 10%;
  background-color: crimson;
  background-color: rgba(196, 2, 2, 1); 
  color: white;
  padding: 3%;
  font-size: 120%;
  border: none;
  cursor: pointer;
}
