.pass-container {
    background-image: url("../images/examples-bg3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 75vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    margin-top: 6%;
}

.pass-title {
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 120%;
    text-shadow: 1px 1px #21554455;
}
.pass-inside-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
}
.pass-input {
    width: 80%;
    border: none;
    border-radius: 5px;
    background: whitesmoke;
    border: gainsboro 1px solid;
    padding: 1%;
    padding-right: 3%;
    font-size: 60%;
    margin-bottom: 10%;
    margin-top: 5%;
    border: none;
    border-radius: 5px;
    direction: ltr;
    text-align: center;
}

.pass-btn {
    border: solid 1px gainsboro;
    border-radius: 5px;
}

@media screen and (max-width:1280px) {
    .pass-container {
        height: 75vh;
        margin-top: 8%;  
        width: 70%;
    }    
    .pass-title {
        font-size: 70%;
    }
}
@media screen and (max-width: 800px) {
    .pass-container {
        height: 55vh;
        margin-top: 30%;
    }
    
    .pass-title {
        font-size: 70%;
    }
}