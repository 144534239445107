.my-industry-select{
    background-color: white;
    width: 98%;
    z-index: 500;
    padding: 0.5%;
    margin-left: 1.5%;
    border-radius: 5px;
}
.add-top-lbl{
    background-color: #f7fbfe;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-size: 120%;
    font-weight: bold;
    text-align: center;
}
@media screen and (max-width: 800px) {
    .my-industry-select{
        width: 90%;
        padding: 0.3%;
        margin-left: 5%;
    }
}
@media screen and (max-width: 540px) {
    .my-industry-select{
        width: 95%;
        padding: 0.3%;
        margin-left: 2%;
    }
}
@media screen and (max-width: 500px) {
    .my-industry-select{
        width: 90%;
        padding: 0.3%;
        margin-left: 5%;
    }
}