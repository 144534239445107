.card-page-main-container {
    background-image: url("../images/card-desktop-bg.PNG");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.card-page-inside-loading {
    background-color: #f7fbfe;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-btn-lbl {
    margin-top: 10%;
    font-size: 80%;
}

.annoying-change-psw2 {
    background-color: crimson;
    width: 300px;
    height: 2cm;
    top: 8%;
    left: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow-x: hidden;
    z-index: 10000;
    position: absolute;
}
.annoying-change-psw-btn2 {
    border: 1px solid gainsboro;
    padding-left: 5%;
    padding-right: 5%;
}
.annoying-change-psw-lbl2 {
    font-size: 100%;
    color: black;
    font-weight: bold;
}

/* width */
*::-webkit-scrollbar {
    width: 2.5mm;
}

/* Track */
*::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: #888888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.phone-bg-div {
    background-image: url("../images/empty-phone.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    width: 11cm;
    height: 22cm;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.card-cover-img {
    background-color: gainsboro;
    width: 9.3cm;
    height: 5cm;
}
.card-face-img {
    width: 3.5cm;
    height: 3.5cm;
    margin-top: -1.1cm;
    box-shadow: 0px 0px 35px -10px black;
}
.certified-img {
    width: 20%;
    position: absolute;
    top: 5.3cm;
    left: 2%;
}
.fullscreen-div {
    background-color: black;
    background-color: rgba(25, 25, 25, 0.5);
    width: 9.3cm;
    height: 16.5cm;
    margin-top: 2.9cm;
    margin-left: 11mm;
    position: absolute;
    z-index: 200000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.main-ghoust-lbl1 {
    font-size: 150%;
    font-weight: bold;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 2%;
}
.main-ghoust-lbl2 {
    font-size: 100%;
    text-align: center;
}
.full-size-x-btn {
    width: 10%;
    margin-right: 3.5mm;
    margin-top: 1mm;
    opacity: 1;
    font-size: 150%;
}
.full-size-img {
    width: 100%;
    margin-top: 25%;
}
.full-size-img2 {
    width: 100%;
    margin-top: 2%;
}
.card-page-inside-container {
    background-color: #f7fbfe;
    width: 9.3cm;
    height: 16.25cm;
    margin-top: 2.9cm;
    margin-left: 11mm;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}
.top-stuff-div {
    padding-bottom: 10%;
    width: 9.3cm;
}
.card-titles3 {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
.sticky-space {
    background: #f1f1f1;
    width: 0.22cm;
    height: 1.2cm;
}
.sticky-save-btns-div {
    // background-color: red;
    width: 9.05cm;
    height: 1.2cm;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 4.2cm;
}
.go-to-pay-div {
    background-color: rgba(60, 90, 118, 1);
    width: 100%;
    position: fixed;
    bottom: 4.2cm;
    // margin-left: 0.5%;
    width: 9.05cm;
    height: 1.2cm;
    padding-top: 1%;
    padding-bottom: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}
.go-to-pay-checkout {
    font-weight: 500;
    color: white;
    margin-bottom: 2px;
    // text-decoration: underline;

    // &:hover {
    //     color: blue !important;
    // }
}
.go-to-pay-div-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.go-to-pay {
    padding: 2%;
    z-index: 1000;
    line-height: 10px;
    color: white;
    cursor: pointer;
    // text-decoration: underline;

    // &:hover {
    //     color: blue !important;
    // }
}

.save-div {
    background-color: rgba(60, 90, 118, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 70%;
    width: 25%;
    height: 100%;
    margin-right: 4%;
    margin-left: 4%;
    color: white;
    cursor: pointer;

    &:hover {
        background-color: crimson;
        color: white;
        height: 1.5cm;
        margin-bottom: 3%;
        font-size: 80%;
        z-index: 10000;
    }
}
.save-btn-icon {
    margin-top: 5%;
}
.save-btn-lbl {
    font-size: 85%;
}
.top-img {
    width: 100%;
    height: 5cm;
}
.face-img {
    position: absolute;
    top: 18%;
    left: 30%;
    width: 4cm;
    height: 4cm;
}
.edit-main-btn {
    position: absolute;
    bottom: 5%;
    width: 2cm;
    height: 2cm;
    background-color: crimson;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}
.edit-bar {
    background-color: rgba(60, 90, 118, 0.9);
    position: absolute;
    bottom: 36mm;
    width: 8.5cm;
    height: 15mm;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.55cm;
    padding-right: 0.55cm;
    color: white;
    transition: opacity 0.5s ease-in;
}
.edit-bar-hidden {
    background-color: rgba(60, 90, 118, 0.9);
    position: absolute;
    bottom: 36mm;
    width: 0.7cm;
    height: 15mm;
    margin-right: 7cm;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 0.55cm;
    padding-right: 0.55cm;
    color: white;
    transition: opacity 0.5s ease-in;
    cursor: pointer;
}
.edit-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #e62148;
    width: 15%;
    padding: 2%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 10px;
}
.hide-lbl {
    margin-left: 5%;
}
.edit-label {
    background-color: #e62148;
    border: none;
    color: white;
}
.name-lbl {
    font-size: 160%;
    font-weight: bold;
    text-align: center;
}
.job-lbl {
    width: 90%;
    font-size: 100%;
    margin-top: 1%;
    text-align: center;
    word-wrap: break-word;
}
.card-btns {
    // background-color: red;
    width: 80%;
    height: 7cm;
    margin-top: 5%;
    margin-left: 5%;
    display: grid;
    margin-top: 5%;
    grid-gap: 5%;
    row-gap: 5%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin-bottom: 5%;
}
.the-card-btns {
    display: grid;
    grid-gap: 5%;
    row-gap: 5%;
    width: 90%;
    margin-left: 5%;
    grid-template-columns: repeat(4, 1fr);
    height: auto;
    margin-bottom: 3%;
}
.card-btn-div {
    // background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.card-btn-circle-div {
    background-color: white;
    width: 50px;
    height: 50px;
    border: 1.5px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
}
.btn-icon {
    width: 50%;
}
.gallery-hidden-div {
    background-color: whitesmoke;
    width: 100%;
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 1.5cm;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
}
.img-div-in-gallery {
    background-color: transparent;
    width: 125%;
    height: auto;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.inside-except-images {
    // background-color: blue;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
}
.hidden-info-btns-div {
    // background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid gainsboro;
    width: 100%;
    margin-left: 0mm;
    // margin-top: 10%;
}
.hide-div {
    // background-color: yellow;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 37%;
    cursor: pointer;
}
.hidden-info-btn {
    background-color: #fafafa;
    color: black;
    width: 100%;
    padding-top: 4%;
    padding-bottom: 4%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 120%;
}
.hidden-info-btn-share {
    background-color: black;
    color: white;
    width: 100%;
    padding-top: 4%;
    padding-bottom: 4%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 120%;
}
.apple-or-android-popup {
    background-color: black;
    background-color: rgba(25, 25, 25, 0.5);
    width: 9.3cm;
    height: 16.5cm;
    margin-top: 2.9cm;
    margin-left: 11mm;
    position: absolute;
    z-index: 200000;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.apple-or-android-btn {
    background-color: whitesmoke;
    width: 45%;
    padding: 2%;
    background-color: crimson;
    color: white;
    border: 1px solid gainsboro;
    border-radius: 5px;
}
.hidden-div {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.hidden-share-div {
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
}
.share_img_btn {
    width: 40px;
    height: 40px;
}
.hidden-info-div {
    background-color: whitesmoke;
    width: 95%;
    padding-left: 5%;
    padding-top: 1%;
    padding-bottom: 3%;
}
.hidden-video-btn {
    background-color: #fafafa;
    border: 1px solid gray;
    color: black;
    width: 75%;
    padding: 4%;
    padding-left: 6%;
    padding-right: 6%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 120%;
}
.hidden-video-btn-tiny {
    background-color: #fafafa;
    border: 1px solid gray;
    color: black;
    width: 75%;
    padding: 4%;
    padding-left: 6%;
    padding-right: 6%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 50%;
}
.bottom-btn-and-icon-div {
    // background-color: yellow;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.hidden-info-btn-tiny {
    background-color: #fafafa;
    border-bottom: 1px solid gray;
    color: black;
    width: 88%;
    padding: 4%;
    padding-left: 6%;
    padding-right: 6%;
    margin-top: 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 50%;
}
.bottom-btn-icon {
    margin-left: 5%;
}
.bottom-btn-lbl2 {
    margin-left: 8%;
    font-size: 90%;
}
.bottom-btn-arrow-icon {
    // background-color: rebeccapurple;
    cursor: pointer;
}
.all-bottom-div {
    // background-color: blue;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.click-to-create-div {
    // background-color: gold;
    width: 75%;
    margin-top: 8%;
    margin-bottom: 20%;
    text-align: center;
}
.click-here-lbl {
    // background-color: pink;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 2%;
}
.to-create-lbl {
    cursor: pointer;
}
.hidden-inside-row {
    background-color: whitesmoke;
    // background-color: skyblue;
    width: 98%;
    margin-top: 1%;
    margin-bottom: 1%;
}
.info-title {
    margin-left: 5%;
    font-weight: bold;
}
.info-val {
    // background-color: skyblue;
    margin-left: 5%;
    font-size: 100%;
}

.hidden-link-div {
    // background-color: red;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 1cm;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 5%;
}
.hidden-about-div {
    // background-color: red;
    width: 90%;
    min-height: 2cm;
}
.hidden-link-lbl {
    margin-top: 1%;
    margin-bottom: 2%;
    font-size: 130%;
}
.hidden-link-url {
    margin-bottom: 5%;
}
.img-in-gallery {
    background-color: transparent;
    width: 72%;
    height: 100%;
    margin-left: 5%;
    cursor: pointer;
}
.video-div-in-gallery {
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.video-btn-in-gallery {
    width: 80%;
    font-size: 80%;
    font-weight: bold;
    padding: 3%;
    padding-right: 5%;
    padding-left: 5%;
    margin: 1%;
    margin-top: 5%;
    margin-bottom: 0%;
    margin-left: 12%;
    background-color: crimson;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.hidden-video-div {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 5%;
}
.hidden-contact-input {
    background-color: white;
    width: 70%;
    border: 1px solid gainsboro;
    padding: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin: 3%;
    border-radius: 5px;
}
.hidden-contact-send-btn {
    background-color: crimson;
    width: 74%;
    color: white;
    border: 1px solid gainsboro;
    padding: 2%;
    margin: 3%;
    border-radius: 5px;
}
.hidden-contact-send-btn-loading-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: crimson;
    width: 73%;
    color: white;
    border: 1px solid gainsboro;
    padding: 1%;
    margin: 3%;
    border-radius: 5px;
}
.my-qr {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
}
.share-btns-div {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: white;
}
.share-btn {
    width: 25%;
    height: 130%;
    border: none;
    cursor: pointer;
}
.share-btn-icon {
    width: 40%;
    color: white;
}
.share-btn-icon2 {
    color: white;
}
.share-popup {
    // background-color: yellow;
    width: 50%;
    height: auto;
    display: grid;
    grid-gap: 2%;
    margin-top: 5%;
    grid-template-columns: 33% 33% 33%;
    margin-bottom: 5%;
}
.share-button1 {
    background-color: gainsboro;
    border-radius: 50%;
    margin-left: 10%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.the-share-div {
    // background-color: blanchedalmond;
    width: 100%;
    margin-bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.the-share-btn {
    width: 50%;
    padding-top: 2%;
    padding-bottom: 2%;
    border: 1px solid gainsboro;
    background-color: rgba(60, 90, 118, 0.9);
    background-color: black;
    color: white;
    border-radius: 8px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.share-lbl {
    // background-color: blueviolet;
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 50%;
    height: 5vh;
    justify-content: space-evenly;
}
.share-btn-icon3 {
    color: white;
    height: 100%;
}
.sms-keyboard-div {
    background-color: black;
    background-color: rgba(25, 25, 25, 0.5);
    width: 9.3cm;
    height: 16.5cm;
    margin-top: 2.9cm;
    margin-left: 11mm;
    position: absolute;
    z-index: 200000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sms-keyboard-input {
    width: 80%;
    margin-bottom: 10%;
    border: none;
    border-radius: 5px;
    background: whitesmoke;
    border: gainsboro 1px solid;
    padding: 5%;
    direction: ltr;
    font-size: 100%;
}
.sms-keyboard-btn {
    background-color: crimson;
    border: 2px solid gainsboro;
    color: white;
    padding: 3%;
    padding-right: 10%;
    padding-left: 10%;
    border-radius: 10px;
}
.send-sms-x-btn {
    background-color: crimson;
    border: 2px solid gainsboro;
    color: white;
    padding: 3%;
    padding-right: 10%;
    padding-left: 10%;
    border-radius: 10px;
    margin-top: 5%;
}
.top-person-images {
    // background-color: brown;
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1920px) and (max-height: 870px) {
    .phone-bg-div {
        width: 10cm;
        height: 20cm;
    }
    .card-page-inside-container {
        width: 8.45cm;
        height: 14.85cm;
        margin-top: 2.6cm;
        margin-left: 10mm;
    }
    .fullscreen-div {
        width: 8.45cm;
        height: 14.85cm;
        margin-top: 2.6cm;
        margin-left: 10mm;
    }

    .sms-keyboard-div {
        width: 100%;
        height: 100%;
        margin-top: 0%;
        margin-left: 0%;
    }

    .sticky-save-btns-div {
        // background-color: bisque;
        width: 8.2cm;
        bottom: 4cm;
    }
    .go-to-pay-div {
        width: 8.2cm;
        bottom: 4cm;
        // margin-left: 0.5%;
    }
}
@media screen and (max-width: 1536px) and (max-height: 722px) {
    .top-stuff-div {
        padding-bottom: 10%;
        width: 9cm;
    }
    .sticky-save-btns-div {
        width: 8.2cm;
        bottom: 2.35cm;
    }
    .go-to-pay-div {
        width: 8.2cm;
        bottom: 2.35cm;
    }
    .card-page-inside-container {
        width: 8.2cm;
        height: 14.35cm;
        margin-top: 2.4cm;
        margin-left: 11mm;
    }
}
@media screen and (max-width: 1280px) and (max-height: 800px) {
    .sticky-save-btns-div {
        // background-color: bisque;
        width: 8.2cm;
        bottom: 3.1cm;
    }
    .go-to-pay-div {
        width: 8.2cm;
        bottom: 3.1cm;
    }
    .the-card-btns {
        width: 80%;
        margin-left: 10%;
    }
}
@media screen and (max-width: 1280px) and (max-height: 577px) {
    .phone-bg-div {
        width: 8cm;
        height: 15cm;
    }
    .card-page-inside-container {
        width: 6.6cm;
        height: 11.4cm;
        margin-top: 1.8cm;
        margin-left: 9mm;
    }
    .certified-img {
        top: 4cm;
    }
    .fullscreen-div {
        width: 6.6cm;
        height: 11.4cm;
        margin-top: 1.8cm;
        margin-left: 9mm;
    }
    .card-cover-img {
        background-color: gainsboro;
        width: 6.3cm;
        height: 3.7cm;
    }
    .card-face-img {
        width: 2.5cm;
        height: 2.5cm;
    }
    .sms-keyboard-div {
        width: 100%;
        height: 100%;
        margin-top: 0%;
        margin-left: 0%;
    }

    .sticky-save-btns-div {
        // background-color: bisque;
        width: 6.6cm;
        bottom: 1.9cm;
    }
    .go-to-pay-div {
        width: 6.6cm;
        bottom: 1.9cm;
    }

    .top-stuff-div {
        padding-bottom: 10%;
        width: 100%;
    }
    .the-card-btns {
        grid-template-columns: repeat(4, 20%);
    }

    .card-btn-circle-div {
        width: 37px;
        height: 37px;
    }
    .bottom-btn-lbl2 {
        margin-left: 7%;
        font-size: 80%;
    }

    .save-btn-lbl {
        font-size: 75%;
    }
    .btn-icon {
        width: 60%;
    }
    .card-btn-lbl {
        margin-bottom: 10%;
        font-size: 70%;
    }
    .info-val {
        font-size: 80%;
    }
    .full-size-x-btn {
        width: 15%;
    }
}
@media screen and (max-width: 1024px) and (max-height: 1366px) {
    .sticky-save-btns-div {
        bottom: 9.9cm;
    }
    .go-to-pay-div {
        bottom: 9.9cm;
    }
    .edit-main-btn {
        bottom: 20%;
    }
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
    .phone-bg-div {
        width: 8cm;
        height: 15cm;
    }
    .card-page-inside-container {
        width: 6.6cm;
        height: 11.4cm;
        margin-top: 1.8cm;
        margin-left: 9mm;
    }
    .certified-img {
        top: 4cm;
    }
    .fullscreen-div {
        width: 6.6cm;
        height: 11.4cm;
        margin-top: 1.8cm;
        margin-left: 9mm;
    }
    .card-cover-img {
        background-color: gainsboro;
        width: 6.3cm;
        height: 3.7cm;
    }
    .card-face-img {
        width: 2.5cm;
        height: 2.5cm;
    }
    .sms-keyboard-div {
        width: 100%;
        height: 100%;
        margin-top: 0%;
        margin-left: 0%;
    }

    .sticky-save-btns-div {
        // background-color: bisque;
        width: 6.6cm;
        bottom: 1.9cm;
    }
    .go-to-pay-div {
        width: 6.6cm;
        bottom: 1.9cm;
    }

    .top-stuff-div {
        padding-bottom: 10%;
        width: 100%;
    }
    .the-card-btns {
        grid-template-columns: repeat(4, 20%);
    }

    .card-btn-circle-div {
        width: 37px;
        height: 37px;
    }
    .bottom-btn-lbl2 {
        margin-left: 7%;
        font-size: 80%;
    }

    .save-btn-lbl {
        font-size: 75%;
    }
    .btn-icon {
        width: 60%;
    }
    .card-btn-lbl {
        margin-bottom: 10%;
        font-size: 70%;
    }
    .info-val {
        font-size: 80%;
    }
    .edit-main-btn {
        bottom: 1%;
        left: 32%;
    }
}

@media screen and (max-width: 920px) {
    .go-to-pay-div {
        bottom: 27%;
    }
    .go-to-pay-checkout {
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 820px) {
    .go-to-pay-div {
        bottom: 24%;
    }
}
@media screen and (max-width: 800px) {
    .certified-img {
        top: 6.5cm;
    }
    .info-val {
        font-size: 100%;
    }
    .annoying-change-psw2 {
        top: 5%;
        left: 10%;
    }
    .save-btn-lbl {
        font-size: 85%;
    }

    .card-cover-img {
        width: 99vw;
        height: auto;
    }
    .card-face-img {
        width: 3.5cm;
        height: 3.5cm;
        margin-top: -1cm; // -1cm;
        box-shadow: 0px 0px 35px -10px black;
    }
    .card-btn-circle-div {
        width: 50px;
        height: 50px;
    }
    .btn-icon {
        width: 50%;
        font-size: 18px;
    }
    .apple-or-android-popup {
        width: 100vw;
        height: 100vh;
        margin-top: 0%;
        margin-left: 0%;
    }
    .sticky-save-btns-div {
        bottom: 19.8%;
    }
    .go-to-pay-div {
        bottom: 19.8%;
    }
    .edit-main-btn {
        bottom: 8%;
        left: 21%;
    }
    .video-btn-in-gallery {
        padding: 3%;
        margin-top: 3%;
        margin-bottom: 3%;
    }
    /* width */
    *::-webkit-scrollbar {
        width: 1mm;
    }
    .card-btn-lbl {
        font-size: 80%;
        margin-bottom: 10%;
    }
    .click-to-create-div {
        font-size: 100%;
    }
    .top-stuff-div {
        width: 100%;
    }
    .bottom-btn-lbl2 {
        margin-left: 8%;
        font-size: 90%;
    }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
    .card-cover-img {
        width: 9.2cm;
        height: auto;
    }
    .edit-main-btn {
        bottom: 13%;
    }
}
@media screen and (max-width: 765px) {
    .card-cover-img {
        width: 99vw;
        height: auto;
    }
    .hidden-info-btns-div {
        // background-color: red;
        width: 100%;
    }
    .fullscreen-div {
        width: 100vw;
        height: 100vh;
        margin-top: 0%;
        margin-left: 0%;
    }
    .card-titles3 {
        margin-bottom: 5%;
    }
}
// @media screen and (max-width: 540px){
//     .go-to-pay-div {
//         width: 90vw;
//         left: 0%;
//         bottom: 0%;
//     }
// }
@media screen and (max-width: 541px) {
    .the-card-btns {
        width: 85%;
        width: 80%;
        margin-left: 10%;
    }
    .save-div {
        margin-right: 2%;
        margin-left: 2%;
    }
    .card-page-inside-container {
        background-color: #f7fbfe;
        width: 100%;
        height: 100%;
        margin-top: 0%;
        margin-left: 0%;
    }
    .sticky-save-btns-div {
        width: 100vw;
        left: 0%;
        bottom: 0%;
    }
    .go-to-pay-div {
        width: 100vw;
        left: 0%;
        bottom: 0%;
    }
    .edit-main-btn {
        bottom: 0%;
        left: 0%;
    }
    .save-div {
        &:hover {
            background-color: rgba(60, 90, 118, 0.7);
            height: 1.5cm;
            margin-bottom: 0%;
            height: 100%;
            font-size: 80%;
            z-index: 10;
        }
    }
}

@media screen and (max-width: 433px) {
    .phone-bg-div {
        width: 100vw;
        height: 100vh;
    }
    .edit-main-btn {
        bottom: 6%;
        left: 0%;
    }
}

@media screen and (max-width: 420px) {
    .save-div {
        margin-right: 2%;
        margin-left: 2%;
    }
    .save-div {
        width: 43%;
    }
    .edit-main-btn {
        bottom: 7%;
        left: 1%;
    }
    .certified-img {
        top: 6.3cm;
    }
}

@media screen and (max-width: 376px) {
    .save-div {
        margin-right: 2%;
        margin-left: 2%;
    }
    .share-button1 {
        background-color: gray;
        border-radius: 50%;
        margin-left: 10%;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .certified-img {
        top: 5.6cm;
    }
}
@media screen and (max-width: 360px) {
    .save-div {
        margin-right: 2%;
        margin-left: 2%;
    }
    .certified-img {
        top: 5.4cm;
    }
}

@media screen and (max-width: 321px) {
    .my-qr {
        padding-bottom: 5%;
    }
    .save-div {
        margin-right: 2%;
        margin-left: 2%;
    }
    .certified-img {
        top: 5cm;
    }
    .card-btn-circle-div {
        width: 42px;
        height: 42px;
    }
}

@media screen and (max-width: 281px) {
    .the-card-btns {
        display: grid;
        grid-gap: 5%;
        row-gap: 5%;
        width: 90%;
        margin-left: 5%;
        grid-template-columns: repeat(4, 1fr);
    }
    .sticky-save-btns-div {
        bottom: 0%;
    }
    .go-to-pay-div {
        bottom: 0%;
    }
    .edit-main-btn {
        bottom: 10%;
        left: 0%;
    }
    .click-to-create-div {
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

@media only screen and (orientation: portrait) {
    .card-btn-circle-div {
        background-color: white;
    }
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
    .card-page-main-container {
        height: 100vw;
        width: 1000vh;
    }
    .sticky-save-btns-div {
        position: absolute;
        width: 40vw;
        bottom: 0%;
    }
    .go-to-pay-div {
        position: absolute;
        width: 40vw;
        bottom: 0%;
    }
    .edit-main-btn {
        bottom: 0%;
        left: 20%;
    }
}
