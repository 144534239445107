.login-container {
    background-image: url("../images/examples-bg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
}
.login-bg-overly{
    background-color: rgba(232, 33, 72, 0.75);  
    background-color: rgba(196, 2, 2, 0.8);   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.login-form-container {
    opacity: 1;
    z-index: 10;
    height: 60%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    font-size: 250%;
    margin: 0%;
    padding: 5%;
    border-radius: 10px;
}

.login-title {
    font-size: 200%;
    margin-top: 10%;
}

.login-input-invalid {
    width: 80%;
    margin-bottom: 5%;
    border: 2px solid red;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    padding: 1%;
    padding-right: 3%;
    direction: ltr;
    font-size: 60%;
}

.login-input {
    width: 80%;
    margin-bottom: 5%;
    border: none;
    border-radius: 5px;
    background: whitesmoke;
    border: gainsboro 1px solid;
    padding: 1%;
    padding-right: 3%;
    direction: ltr;
    font-size: 60%;
}
.login-ok-btn {
    font-size: 60%;
    margin-top: 0%;
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 1%;
    padding-bottom: 1%;
    border: solid 1px gainsboro;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    color: black;
    cursor: pointer;
}

.error_lable {
    font-size: 50%;
    color: red;
    margin-bottom: 5%;
    margin-top: -2%;
}

.loading-signin-container {
    background-color: whitesmoke;
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.goToRegister-link {
    cursor: pointer;
    margin-left: 2%;
    color: white;
}

.goToRegister-container {
    width: 95%;
    margin-top: 5%;
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.goToForgot-container {
    width: 95%;
    margin-top: -3%;
    margin-bottom: 2%;
    margin-left: -58%;
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 60%;
    color: white;
}

@media screen and (max-width: 1280px) {
    .login-container {
        height: 77vh;
        margin-top: 8%;
        width: 70%;
        font-size: 70%;
    }
    .login-title {
        font-size: 180%;
        margin-top: 0%;
    }
    .goToRegister-container {
        font-size: 70%;
    }
    .login-input {
        padding: 2%;
        padding-right: 3%;
    }
}
@media screen and (max-width: 800px) {
    .login-container {
        height: 55vh;
        margin-top: 20%;
        width: 90%;
        font-size: 100%;
    }

    .login-form-container {
        height: 85%;
        width: 90%;
        font-size: 200%;
        margin-top: 2%;
        border-radius: 10px;
    }
    .login-input-invalid {
        margin-top: 5%;
        margin-bottom: 0%;
    }
    .login-ok-btn {
        margin-top: 10%;
    }
    .login-title {
        font-size: 150%;
        margin-top: 0%;
    }
    .login-input {
        padding: 1%;
        padding-right: 3%;
    }
    .goToRegister-container {
        font-size: 50%;
    }
    .goToForgot-container {
        font-size: 50%;
        margin-top: 0%;
        margin-left: -38%;
    }
}
