.alert-container{    
    background-color: snow;
    width: 40%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;     
    z-index: 1000;   
    padding: 3%;
    border: 4px solid black;
    border-radius: 10px;
}
.exit-btn-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.exit-popup-btn{
    border: none;
    background: none;  
    font-size: 30px;  
}
.alert-text{
    text-align: center;
}
.msg{
    color:black;
    direction: ltr;
    font-size: 100%;
}
.ok-btn-container{
    margin-top: 5%;
}
.ok-popup-btn{
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    padding: 10%;
    padding-left: 30%;
    padding-right: 30%;
    border: 2px solid black;
}
@media screen and (max-width: 800px) {
    .alert-container{    
        background-color: skyblue;
        width: 80%;
        position: absolute;
        top:5%;
        left:10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;     
        z-index: 1000;   
        padding: 2%;
        border: 4px solid black;
    }
}