.preview-phone-main-container {
    // background-color: blue;
    width: 100%;
    height: 100%;
    font-size: 500%;
}
.video-and-btn-row {
    // background-color: blue;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2%;
    margin-bottom: 2%;
}
.remove_video_btn {
    // border: 1px solid black;
    border: none;
    background-color: transparent;
    font-weight: bolder;
    font-size: 80%;
    color: red;
    cursor: pointer;
}
.my-link-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 3%;
    justify-content: space-evenly;
}

.my-link-lbl1 {
    font-size: 120%;
    font-weight: bold;
}
.my-link-lbl2 {
    font-size: 100%;
}
.blabla2 {
}

.hidden-info-btn-prev {
    background-color: #fafafa;
    color: black;
    width: 100%;
    padding-top: 4%;
    padding-bottom: 4%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 120%;
    border-bottom: 1px solid gainsboro;
}
.preview-phone-div {
    background-image: url("../images/empty-phone.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    // background-color: yellow;
    width: 11cm;
    height: 22cm;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;
    right: 1%;
}
.preview-phone-inside-div {
    background-color: white;
    margin-left: 5mm;
    height: 75%;
    width: 9.4cm;
    display: flex;
    flex-direction: column;
    position: relative;
}
.preview-phone-inside-div-scrollable {
    // background-color: yellow;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.top-stuff-div2 {
    padding-bottom: 10%;
    width: 9.3cm;
}
.preview-phone-inside-div-scrollable-tiny {
    // background-color: yellow;
    width: 100%;
}
.preview-cover-img {
    background-color: gainsboro;
    // border-bottom: 1px solid gainsboro;
    width: 100%;
    height: auto;
    height: 5cm;
}
.preview-face-img {
    width: 3.5cm;
    height: 3.5cm;
    margin-top: -1.5cm; 
    box-shadow: 0px 0px 35px -10px black;
}
.preview-titles{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.preview-title1 {
    // background-color: blue;
    font-weight: bold;
    font-size: 130%;
    text-align: center;
    width: 100%;
}
.preview-title2 {
    // background-color: blue;
    width: 90%;
    font-size: 120%;
    margin-top: 1%;
    text-align: center;
    margin-bottom: 5%;
    word-wrap: break-word;
    align-self: center;
}

.card-btns2 {
    // background-color: blanchedalmond;
    display: grid;
    grid-gap: 5%;
    row-gap: 5%;
    width: 90%;
    margin-left: 5%;
    grid-template-columns: repeat(4, 1fr);
    height: auto;
    margin-bottom: 3%;
}
.card-btn-div2 {
    // background-color: red;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.preview-card-btn-lbl {
    margin-top: 10%;
    font-size: 80%;
}
.video-div1 {
    width: 100%;
    border-radius: 1px solid black;
}
.contact-div1 {
    // background-color: pink;
    width: 100%;
}
.info-div1 {
    background-color: whitesmoke;
    width: 94.1%;
    border: 1px solid black;
    padding-top: 1%;
    padding-bottom: 3%;
    min-height: 2cm;
}
.info-div1-tiny {
    background-color: whitesmoke;
    width: 97%;
    border: 1px solid black;
    padding-top: 1%;
    padding-bottom: 3%;
    min-height: 1cm;
    font-size: 40%;
}
.about-div2 {
    width: 89.1%;
    min-height: 2cm;
    padding-left: 5%;
    background-color: whitesmoke;
    border: 1px solid black;
    padding-bottom: 3%;
}
.about-div2-tiny {
    width: 92%;
    min-height: 2cm;
    font-size: 65%;
    padding-left: 5%;
    background-color: whitesmoke;
    border: 1px solid black;
    padding-bottom: 3%;
    margin-bottom: 10%;
}
.gallery-div1 {
    width: 86.1%;
    min-height: 1.5cm;
    background-color: whitesmoke;
    border: 1px solid black;
    padding-top: 2%;
    display: grid;
    grid-gap: 3%;
    row-gap: 1%;
    padding: 4%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
}
.gallery-div1-tiny {
    width: 97%;
    min-height: 1.5cm;
    background-color: whitesmoke;
    border: 1px solid black;
    padding-top: 10%;
    padding-bottom: 10%;
}
.img-div-in-gallery2 {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    position: relative;
}
.img-div-in-gallery-tiny {
    background-color: transparent;
    width: 100%;
    height: 100%;
    margin-bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

/*--------------TINY--------------------*/

.preview-tiny-phone-div {
    background-image: url("../images/new_phone_bg2.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    width: 4cm;
    height: 6cm;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 5.5%;
}
.preview-tiny-phone-inside-div {
    background-color: white;
    width: 2.8cm;
    height: 5.66cm;
    margin-left: 0.5mm;
    margin-bottom: 0mm;
    overflow-x: hidden;
    position: relative;
    border-radius: 10px;
}
.preview-tiny-phone-div-container {
    // background-color: gray;
    width: 100%;
    height: 37vh;
    position: absolute;
    top: 0%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.preview-tiny-cover-img {
    width: 100%;
    height: 1.5cm;
}
.preview-tiny-face-img {
    margin-top: -0.5cm;
    width: 1.2cm;
    height: 1.2cm;
    box-shadow: 1px 1px 3px #888888;
}
.preview-tiny-titles {
    // background-color: yellow;
    width: 97%;
    margin-top: 1mm;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.preview-tiny-title1 {
    font-weight: bold;
    font-size: 60%;
    text-align: center;
}
.preview-tiny-title2 {
    font-size: 50%;
    margin-top: 1%;
    text-align: center;
}

.card-btns2-tiny {
    // background-color: red;
    width: 82%;
    height: 5cm;
    margin-top: 5%;
    display: grid;
    grid-gap: 8%;
    row-gap: 10%;
    grid-template-columns: repeat(4, 20%);
    grid-template-rows: repeat(2, 45%);
}
.card-btns-tiny {
    // background-color: red;
    width: 85%;
    margin: 5%;
    margin-bottom: 0%;
    display: grid;
    grid-gap: 10%;
    row-gap: 0%;
    font-size: 40%;
    grid-template-columns: repeat(4, 18%);
}
.card-btn-circle-div2 {
    width: 50px;
    height: 50px;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.bottom-btn-lbl {
    // background-color: red;
    width: 80%;
    margin-left: 8%;
}

.save-details-btn2 {
    width: 25%;
    background-color: #27ae60;
    color: white;
    font-size: 80%;
    border: none;
    border-radius: 5px;
    padding: 1%;
    cursor: pointer;
    margin-left: 70%;
}

@media screen and (max-width: 1536px) and (max-height: 722px) {
    .preview-phone-div {
        width: 8.7cm;
        height: 16.5cm;
        margin-top: 2%;
        right: 1%;
    }
    .preview-phone-inside-div {
        margin-left: 2mm;
        height: 75.2%;
        width: 7cm;
    }
    .top-stuff-div2 {
        padding-bottom: 10%;
        width: 6.8cm;
    }
    .preview-cover-img {
        height: 4cm;
    }
    .preview-face-img {
        width: 2.5cm;
        height: 2.5cm;
        margin-top: -0.9cm;
    }
    .preview-title1 {
        font-size: 120%;
    }
    .preview-title2 {
        font-size: 95%;
    }

    .card-btns2 {
        width: 90%;
    }
    .card-btn-circle-div2 {
        width: 40px;
        height: 40px;
    }
}
@media screen and (max-width: 1450px) {
    .preview-phone-div {
        margin-top: 3%;
    }
}
@media screen and (max-width: 1350px) {
    .preview-phone-div {
        margin-top: 4%;
    }
}

@media screen and (max-width: 1280px) {
    .preview-phone-div {
        margin-top: 2%;
    }
}
@media screen and (max-width: 800px) {
    .video-and-btn-row {
        // background-color: blue;
        font-size: 50%;
        width: 80%;
        margin-top: 20%;
    }
    .top-stuff-div2 {
        width: 100%;
    }
    .preview-cover-img {
        width: 100%;
    }
    .card-btn-circle-div2 {
        width: 15px;
        height: 15px;
        border: 0.1px solid;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .preview-card-btn-lbl {
        // background-color: burlywood;
        font-size: 5px;
        margin-bottom: 30%;
    }
    .bottom-btn-lbl {
        font-size: 50%;
    }
}

@media screen and (max-width: 540px) {
    .preview-tiny-phone-div {
        top: 10.8%;
    }
}
@media screen and (max-width: 435px) {
    .preview-tiny-phone-div {
        top: 5.5%;
    }
}
@media screen and (max-width: 412px) {
    .preview-tiny-phone-div {
        top: 5.8%;
    }
}
@media screen and (max-width: 411px) and (max-height: 823px) {
    .preview-tiny-phone-div {
        top: 4.8%;
    }
}
@media screen and (max-width: 411px) and (max-height: 732px) {
    .preview-tiny-phone-div {
        top: 5.3%;
    }
}
@media screen and (max-width: 375px) and (max-height: 812px) {
    .preview-tiny-phone-div {
        top: 5%;
    }
}
@media screen and (max-width: 375px) and (max-height: 667px) {
    .preview-tiny-phone-div {
        top: 6.2%;
    }
}
@media screen and (max-width: 360px) {
    .preview-tiny-phone-div {
        top: 6.2%;
    }
}
@media screen and (max-width: 320px) {
    .preview-tiny-phone-div {
        top: 7.2%;
    }
}
