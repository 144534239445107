.privacy-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    background-color: white;
}

.title-img {
    width: 100%;
}

.privacy-text-container {
    width:60%;
    margin-top: 12%;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: top;
    overflow-y: auto;
    margin-bottom: 5%;
}

.privacy-bold {
    font-weight: bold;
}

@media screen and (max-width: 800px) {
    .privacy-text-container {
        width:85%;
        margin-top: 50%;
    }
}
