.create-card-page-main-container {
    background-color: #eff3f6;
    width: 100%;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
}
.sign-container {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f7fbfe;
    border: 1px solid gainsboro;
    border-radius: 10px;
    margin-top: 2%;
    margin-bottom: 5%;
}
.signedin-as-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.signedin-as-img {
    height: 50px;
    border-radius: 50%;
    margin-right: 2%;
}
.signedin-as-lbl1 {
    margin-right: 1%;
}
.signedin-as-lbl11 {
    margin-right: 0%;
}
.signedin-as-lbl2 {
    margin-right: 1%;
    font-weight: bold;
}
.signout-link-2 {
    margin-right: 0.5%;
    color: blue;
    cursor: pointer;
}
.create-card-signup-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.create-card-signup-input {
    width: 80%;
    margin: 5%;
    margin-left: 1%;
    margin-right: 1%;
    border: none;
    border-radius: 5px;
    background: whitesmoke;
    border: gainsboro 1px solid;
    padding: 1%;
    direction: ltr;
    font-size: 60%;
}
.create-card-signup-btn {
    width: 50%;
    margin: 5%;
    margin-left: 1%;
    margin-right: 1%;
    padding: 1%;
    border: solid 1px gainsboro;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    font-weight: bold;
    color: black;
    &:hover {
        background-color: crimson;
        color: white;
    }
}
.editor-div {
    // background-color: black;
    width: 100%;
    border: 1px solid gainsboro;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5%;
    margin-bottom: 1%;
}
.rdw-editor-main{
    height: auto;
}
.add-about-lbl {
    background-color: #f7fbfe;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    font-size: 120%;
    font-weight: bold;
    text-align: center;
}
.wrapper-class {
    width: 100%;
}
.editor-class {
    background-color: white;
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: 5cm;
    overflow-y: hidden;
}
.toolbar-class {
}
.my-text-editor {
}
.rdw-editor-toolbar {
    margin-bottom: 0.5px;
}
.loading-main-div {
    // background-color: blanchedalmond;
    width: 100%;
    margin-top: 4%;
    height: 92vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading-div {
    background-color: rgb(60, 90, 118);
    width: 40%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 20%;
    left: 20%;
    z-index: 2000;
    border: 3px solid rgb(60, 90, 118);
    font-size: 130%;
    color: white;
}
.loading-div-mobile {
    background-color: rgb(60, 90, 118);
    width: 90%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    border: 3px solid rgb(60, 90, 118);
    top: 10%;
    left: 5%;
    z-index: 15000;
    font-size: 100%;
    padding-top: 7%;
    padding-bottom: 7%;
    color: white;
}
.main-loading-lbl {
    font-size: 200%;
    font-weight: bold;
    margin-bottom: 5%;
    color: black;
}
.loading-lbl {
    font-size: 200%;
    font-weight: bold;
    margin-bottom: 5%;
}
.loading-lbl2 {
    font-size: 150%;
    margin-bottom: 5%;
    text-align: center;
}
.create-card-page-inside-main-container {
    // background-color: red;
    width: 70%;
    margin-left: 5%;
    margin-top: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.add-listing-title1 {
    margin-top: 3%;
    font-size: 150%;
}
.add-listing-sub-title {
    margin-top: 1%;
    font-size: 120%;
}

.add-video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 10px;
    margin-top: 2%;
}
.add-video-lbl {
    background-color: #f7fbfe;
    border-radius: 10px;
    width: 100%;
    font-size: 120%;
    font-weight: bold;
    margin-bottom: 10%;
}
.add-about-textarea-container {
    // background-color: blue;
    width: 78.5%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
}
.add-about-lbl2 {
    background-color: #f7fbfe;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 0.5%;
    padding-bottom: 1%;
    font-size: 120%;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-bottom: -0.5%;
}
.save-details-btn {
    width: 100%;
    background-color: #27ae60;
    color: white;
    font-size: 120%;
    margin-bottom: 10%;
    border: none;
    border-radius: 5px;
    padding: 0.5%;
    cursor: pointer;
}

/*--------------TINY--------------------*/
.create-card-page-inside-main-container-mobile {
    // background-color: red;
    width: 98%;
    margin-left: 2%;
    height: 70vh;
    margin-top: 72%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media screen and (max-width: 1700px) {
    .create-card-page-inside-main-container {
        margin-left: 1%;
        width: 72%;
    }
}
@media screen and (max-width: 1560px) {
    .create-card-page-inside-main-container {
        // background-color: red;
        margin-left: 1%;
        width: 70%;
    }
}
@media screen and (max-width: 1445px) {
    .create-card-page-inside-main-container {
        // background-color: red;
        width: 68%;
    }
}
@media screen and (max-width: 1345px) {
    .create-card-page-inside-main-container {
        // background-color: red;
        width: 65%;
    }
}

@media screen and (max-width: 1230px) {
    .create-card-page-inside-main-container {
        // background-color: red;
        width: 60%;
    }
    .signedin-as-container {
        font-size: 80%;
    }
}
@media screen and (max-width: 1180px) {
    .create-card-page-inside-main-container {
        // background-color: red;
        align-items: center;
        width: 95%;
    }
    .signedin-as-container {
        font-size: 80%;
    }
}

@media screen and (max-width: 1060px) {
    .create-card-page-main-container {
        align-items: center;
    }

    .create-card-page-inside-main-container {
        width: 90%;
        margin-left: 1%;
        margin-top: 45%;
    }
}

@media screen and (max-width: 800px) {
    .editor-div {
        margin-bottom: 5%;
    }
    .create-card-page-main-container {
        // background-color: yellow;
    }

    .add-listing-title1 {
        margin-top: 0%;
    }
    .signedin-as-img {
        border-radius: 50%;
        margin-right: 0%;
        margin-bottom: 2%;
        margin-top: 5%;
    }
    .create-card-page-inside-main-container {
        background-color: palevioletred;
        width: 90%;
        margin: 0%;
        margin-top: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .sign-container {
        width: 100%;
        height: 40vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #f7fbfe;
        border: 1px solid gainsboro;
        border-radius: 10px;
        margin-top: 2%;
        margin-bottom: 5%;
        padding-bottom: 5%;
    }

    .signedin-as-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .signedin-as-img {
        height: 50px;
        border-radius: 50%;
        margin-right: 2%;
    }
}
@media screen and (max-width: 540px) {
    .create-card-page-inside-main-container {
        margin-top: 45%;
    }
    .create-card-page-inside-main-container-mobile {
        margin-top: 55%;
    }
}
@media screen and (max-width: 432px) {
    .create-card-page-inside-main-container-mobile {
        margin-top: 60%;
    }
}
@media screen and (max-width: 414px) {
    .create-card-page-inside-main-container {
        margin-top: 70%;
    }
}
@media screen and (max-width: 411px) {
    .create-card-page-inside-main-container {
        margin-top: 70%;
    }
}
@media screen and (max-width: 375px) {
    .create-card-page-inside-main-container {
        margin-top: 80%;
    }
    .create-card-page-inside-main-container-mobile {
        margin-top: 67%;
    }
}
@media screen and (max-width: 360px) {
    .create-card-signup-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 5%;
    }
    .create-card-signup-input {
        margin: 2%;
        padding: 5%;
    }
    .create-card-signup-btn {
        margin: 2%;
        padding: 3%;
    }
    .create-card-page-main-container {
        // background-color: bisque;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .create-card-page-inside-main-container {
        // background-color: red;
        margin-top: 75%;
    }
    .create-card-page-inside-main-container-mobile {
        margin-top: 70%;
    }
}

@media screen and (max-width: 320px) {
    .create-card-page-inside-main-container-mobile {
        margin-top: 80%;
    }
}
@media screen and (max-width: 280px) {
    .create-card-page-inside-main-container-mobile {
        margin-top: 95%;
    }
}
