.add-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 10px;
    margin-top: 2%;
    margin-bottom: 1%;
    padding-bottom: 5%;
}
.add-details-title-div {
    // background-color: yellow;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 1%;
    text-align: start;
}
.add-details-title-div2 {
    // background-color: yellow;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 1%;
    text-align: start;
}
.add-details-lbl {
    // background-color: yellowgreen;
    width: 100%;
    margin-top: 2%;
    padding-bottom: 1%;
    margin-left: 2%;
    color: black;
}
.add-details-must-lbl {
    color: red;
}
.add-link-input {
    background-color: white;
    width: 95%;
    border: 1px solid gainsboro;
    padding: 1%;
    margin-left: 2%;
    border-radius: 5px;
    margin-bottom: 0.5%;
}
.add-details-input {
    background-color: white;
    width: 95%;
    border: 1px solid gainsboro;
    padding: 1%;
    margin-left: 2%;
    border-radius: 5px;
}
::placeholder {
    color: lightslategray;
}

.add-details-places {
    width: 97%;
    margin-left: 2%;
}

@media screen and (max-width: 770px) {
    .add-details-container {
        width: 96%;
    }
    .add-details-input {
        width: 90%;
        padding: 2%;
    }
    .add-link-input {
        width: 90%;
        padding: 2%;
        margin-bottom: 0.7%;
    }
}

@media screen and (max-width: 450px) {
    .add-details-container {
        width: 100%;
        // font-size: 80%;
    }

    .add-details-title-div {
        margin-top: 5%;
    }
    .add-details-title-div2 {
        margin-top: 0%;
    }
    .add-details-input {
        width: 85%;
        margin-left: 5%;
        margin-bottom: 3%;
        padding: 2%;
    }
    .add-link-input {
        width: 85%;
        margin-left: 5%;
        margin-bottom: 3%;
        padding: 2%;
        margin-bottom: 0.8%;
    }
    .add-details-lbl {
        margin-left: 5%;
        margin-bottom: 1%;
        margin-top: 2%;
        font-size: 90%;
    }
}

@media screen and (max-width: 325px) {
    .add-details-lbl {
        font-size: 90%;
    }
}
@media screen and (max-width: 290px) {
    .add-details-lbl {
        font-size: 70%;
        font-weight: bold;
    }
}
