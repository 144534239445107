.my-profile-main-container {
    // background-color: skyblue;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 80%;
    overflow-x: hidden;
}

.my-profile-title {
    background-color: #eef2f4;
    width: 100%;
    padding: 1%;
    padding-left: 5%;
    font-size: 120%;
    font-weight: bold;
    margin-top: 4.8%;
}

.profile-title-username {
    // background-color: #eef2f4;
    width: 100%;
    margin-top: 5%;
    margin-left: 10%;
    font-size: 120%;
    font-weight: bold;
}
.profile-username-div {
    background-color: white;
    width: 86%;
    margin: 2%;
    margin-top: 1%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border: 1px solid gainsboro;
    border-radius: 10px;
}
.profile-usermane-input {
    width: 50%;
    border-radius: 5px;
    border: 1px solid gainsboro;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 1.5%;
    padding: 1%;
    font-size: 100%;
}
.detail-title2 {
    font-size: 130%;
    margin-bottom: 2%;
    margin-top: 1%;
}
.profile-psw-input {
    width: 80%;
    border-radius: 5px;
    border: 1px solid gainsboro;
    padding: 2%;
    margin-right: 0%;
    font-size: 110%;
}
.profile-title-psw {
    // background-color: #eef2f4;
    width: 100%;
    margin-left: 10%;
    font-size: 120%;
    font-weight: bold;
    margin-top: 5%;
}
.profile-psw-div {
    background-color: white;
    width: 86%;
    margin: 2%;
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border: 1px solid gainsboro;
    border-radius: 10px;
}
.psw-bottom-lbl {
    margin-top: 1%;
}
.inputs-2-row-div {
    width: 99%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.inputs-1-row-div {
    width: 100%;
    // background-color: greenyellow;
    display: flex;
    flex-direction: column;
}
.update-profile-btn {
    background-color: crimson;
    color: white;
    width: 20%;
    margin-top: 1%;
    padding: 0.5%;
    font-weight: bold;
    border: none;
    border-radius: 5px;
}

.delete-profile-btn {
    background-color: #d9534f;
    width: 8%;
    color: white;
    padding: 0.6%;
    border: none;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid black;
}
.delete-icon {
    margin-right: 1%;
}
.delete-lbl {
    margin-left: 1%;
}

@media screen and (max-width: 1650px) {
    .my-profile-title {
        margin-top: 6%;
    }
}
@media screen and (max-width: 1400px) {
    .my-profile-title {
        margin-top: 7%;
    }
}
@media screen and (max-width: 1280px) {
    .my-profile-title {
        margin-top: 5%;
    }
    .profile-title-psw {
        margin-top: 3%;
    }
}
@media screen and (max-width: 1200px) {
    .my-profile-title {
        margin-top: 8%;
    }
}
@media screen and (max-width: 1100px) {
    .my-profile-title {
        margin-top: 9%;
    }
}
@media screen and (max-width: 800px) {
    .my-profile-title {
        margin-top: 10%;
    }

    .profile-usermane-input {
        width: 90%;
        padding: 2%;
        margin-top: 3%;
        margin-bottom: 2%;
    }
    .update-profile-btn {
        width: 50%;
        margin-top: 3%;
        margin-bottom: 2%;
        padding: 2%;
    }
    .profile-title-psw {
        margin-top: 5%;
        margin-bottom: 2%;
    }
    .inputs-2-row-div {
        flex-direction: column;
    }
    .profile-title-username {
        margin-top: 15%;
        margin-bottom: 2%;
    }
    .detail-title2 {
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .delete-profile-btn {
        width: 50%;
        justify-content: space-evenly;
        margin-top: 10%;
        margin-bottom: 15%;
    }
    .profile-psw-input {
        padding: 5%;
    }
    .profile-usermane-input {
        font-size: 120%;
        width: 80%;
        padding: 5%;
    }
    .psw-bottom-lbl {
        margin-top: 5%;
    }
}
