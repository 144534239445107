:root {
  --color-background: white;
}

.main-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.page-container {
  width: 100%;
  height: 93vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: 200%;
  overflow-y: auto;  
}