.examples-div {
    background-image: url("../images/bg11.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.examples-div-mobile {
    background-image: url("../images/examples-bg3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg-overly{
    background-color: rgba(196, 2, 2, 0.85); 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.image-item {
    padding: 18px 0 18px 1px;
    // cursor: pointer;
    cursor: default !important;
}

.carousel-div {
    width: 60%;
    cursor: pointer;
    margin-bottom: 5%;
}

.examples-main-title {
    font-size: 200%;
    margin-top: 5%;
    color: white;
    font-weight: bold;
}

.examples-sub-title {
    font-size: 170%;
    margin-bottom: 2%;
    color: white;
}

.carousel-div-mobile {
    width: 80%;
    margin-bottom: 5%;
}
