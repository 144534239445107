.bottom-bar-main-container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid gainsboro;
}

.div5-BrowserView {
  // background-color: red;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1%;
}

.bottom-bar-inside-container {
  // background-color: gold;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo-link-bottom-img {
  // background-color: purple;
  width: 25%;
}

.links-bottom {
  // background-color: pink;
  width: 25%;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.hashlink-bottom {
  // background-color: royalblue;
  margin-left: 5%;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rights-2021-lbl {
  // background-color: yellow;
  margin-left: 5%;
}

/*----------------MOBILE---------------------*/

.div5-MobileView {
  // background-color: pink;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 5%;
  border-top: 1px solid gainsboro;
}
.bottom-bar-inside-container-mobile {
  // background-color: goldenrod;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0%;
}

.logo-link-bottom-img-mobile {
  width: 45%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.links-bottom-mobile {
  // background-color: yellowgreen;
  width: 90%;
  margin-top: 1%;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 100%;
  margin-bottom: 3%;
}

.hashlink-bottom-mobile {
  // background-color: yellow;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 100%;
}
.hashlink-bottom-mobile-img {
  // background-color: tan;
  width: 100%;
  margin-left: 0%;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 2%;
}
.rights-2021-lbl-mobile {
  font-size: 80%;
  text-align: center;
}

@media screen and (max-width: 1290px) {
  .links-bottom {
    // background-color: salmon;
    width: 40%;
  }
}

@media screen and (max-width: 1280px) {
  .links-bottom {
    // background-color: salmon;
    width: 35%;
  }
}
@media screen and (max-width: 1180px) {
  .links-bottom {
    // background-color: salmon;
    width: 30%;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1366px) {
  .rights-2021-lbl-mobile {
    // background-color: pink;
    font-size: 210%;
  }
  .hashlink-bottom-mobile {
    font-size: 200%;
  }
}
@media screen and (max-width: 768px) and (max-height: 1024px) {
  .rights-2021-lbl-mobile {
    // background-color: pink;
    font-size: 150%;
  }
  .hashlink-bottom-mobile {
    font-size: 160%;
  }
}
@media screen and (min-height: 730px) {
  .links-bottom-mobile {
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 700px) {
  .rights-2021-lbl-mobile {
    // background-color: pink;
    font-size: 80%;
  }
  .hashlink-bottom-mobile {
    font-size: 100%;
  }
}
