.create-gallery-main-container {
    // background-color: yellow;
    width: 100%;
    position: relative;
}
.are-you-sure-img-popup {
    background-color: #EAB1C3;
    width: 40%;
    height: 50%;
    position: fixed;
    top: 15%;
    left: 20%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 5px solid crimson;
    border-radius: 0px;
    font-size: 200%;
    text-align: center;
}
.are-you-sure-btn2 {
    width: 25%;
    background-color: lightgray;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 2%;
    font-size: 80%;
    font-weight: bold;
    cursor: pointer;
}
.chosen-images-div {
    width: 90%;
    display: grid;
    grid-gap: 3%;
    row-gap: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    grid-template-columns:  repeat(5,1fr);
    grid-template-rows: auto;
}
.a-chosen-img-div {
    // background-color: bisque;
    position: relative;
    width: 100%;
    height: 150px;
    margin-bottom: 10%;
}
.a-chosen-img {
    width: 100%;
    height: 150px;
    max-height: 100%;
    max-width: 100%;
}
.remove-img-btn2 {
    background-color: transparent;
    position: absolute;
    top: -5%;
    left: -5%;
    border: none;
}
.remove-img-btn3 {
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: -7%;
    right: -7%;
    border: none;
    width: 30px;
    height: 30px;
    font-size: 130%;
}
.del-img-icon {
}
@media screen and (max-width: 800px) {
    .chosen-images-div {
        display: grid;
        grid-gap: 1%;
        row-gap: 2%;
        margin-top: 3%;
        margin-bottom: 0%;
        grid-template-columns: repeat(3, 100px);
        grid-template-rows: repeat(4, 100px);
        padding-bottom: 10%;
    }
    .a-chosen-img-div {
        // background-color: bisque;
        position: relative;
        margin-right: 1%;
        height: 100%;
    }
    .a-chosen-img {
        width: 98%;
        height: 95%;
    }
    .remove-img-btn2 {
        background-color: white;
        position: absolute;
        top: 0%;
        left: 0%;
        border: none;
    }
    .remove-img-btn3 {
        background-color: black;
        color: white;
        border: none;
        border-radius: 50%;
        position: absolute;
        top: 0%;
        right: 5%;
        border: none;
        width: 20px;
        height: 20px;
        font-size: 100%;
    }
    .are-you-sure-img-popup {
        width: 80%;
        height: 50%;
        top: 15%;
        left: 10%;
        border: 3px solid crimson;
        font-size: 120%;
    }
    .are-you-sure-btn2 {
        width: 25%;
        background-color: lightgray;
        border: 1px solid gray;
        border-radius: 5px;
    }
}
