.home-page-main-container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  overflow-y: hidden;
  overflow-x: hidden;
}