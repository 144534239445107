.dashboard-main-container {
    background-color: snow;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
}
.row-img {
    width: 60px;
    height: 60px;
    padding: 1%;
    cursor: pointer;
    background-color: transparent;
}
.row-lbl {
    cursor: pointer;
}
.inside-table {
    background-color: #fafbfb;
    width: 90%;
    height: 100%;
    margin-top: 20%;
    margin-bottom: 15%;
}

.inside-main {
    background-color: #fafbfb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.left-navbar {
    width: 13%;
    height: 100%;
    background-color: #2c3e50;
    display: flex;
    flex-direction: column;
    color: white;
    padding-top: 5%;
    flex: 1;
}
.left-navbar-top-btn {
    height: 6%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-top: 1%;
    margin-bottom: 5%;
}
.left-navbar-btn {
    width: 90%;
    height: 6%;
    padding-left: 10%;
    font-size: 100%;
    color: white;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    vertical-align: middle;
    text-decoration: none;

    &:hover {
        background-color: #112f4f4f;
        cursor: pointer;
    }
}
.left-navbar-title {
    font-size: 140%;
    letter-spacing: 1px;
    font-weight: bold;
}
.left-navbar-label {
    margin-left: 5%;
}

@media screen and (max-width: 1500px) {
    .left-navbar {
        font-size: 90%;
    }
    .left-navbar-top-btn {
        margin-top: 10%;
    }
}

@media screen and (max-width: 1280px) {
    .left-navbar-label {
        font-size: 90%;
    }
}
@media screen and (max-width: 1200px) {
    .left-navbar {
        font-size: 80%;
    }
}