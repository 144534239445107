.edit-card-render-div{
    // background-color: skyblue;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.remove_img_btn{
    margin-top: 10%;
    border-radius: 50%;
    padding: 8%;
    padding-top: 4%;
    padding-bottom: 4%;
    border: 1px solid black;
    background-color: white;
    font-weight: bolder;
    font-size: 80%;
    color: red;
    position: absolute;
    top: -30%;
    left: -5%;
    cursor: pointer;
}

.remove_img_btn-tiny{
    margin-top: 10%;
    border-radius: 50%;
    padding: 3%;
    padding-top: 2%;
    padding-bottom: 2%;
    border: 1px solid black;
    background-color: white;
    font-weight: bolder;
    font-size: 50%;
    color: red;
    position: absolute;
    top: -20%;
    left: 5%;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .edit-card-render-div{
        height: 97vh;
    }
}